import { DatePipe } from "@angular/common";
import { Injector } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { CharCaseHelper } from "@shared/helpers/CharCaseHelper";
import { HealthRegisterLabelDto } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "../../../shared/common/app-component-base";

export abstract class HealthRegisterBaseService extends AppComponentBase {

    lSource: string = AppConsts.localization.defaultLocalizationSourceName;

    constructor(
        protected datePipe: DatePipe,
        injector: Injector,
    ) {
        super(injector);
    }


    resolveLanguage(label: HealthRegisterLabelDto): string {
        if (label == null) {
            return null;
        }
        const currentLanguage = this.localization.currentLanguage;
        switch (currentLanguage.name) {
            case 'de-CH':
                return CharCaseHelper.capitalizeFirstLetter(label.labelDe);
            case 'fr-CH':
                return CharCaseHelper.capitalizeFirstLetter(label.labelFr);
            case 'it-CH':
                return CharCaseHelper.capitalizeFirstLetter(label.labelIt);
            default:
                return null;
        }
    }

    resolveDate(date) {
        return date == null ? '-' : this.datePipe.transform(date.toJSDate());
    }
}
