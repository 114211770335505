import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, delay, concatMap } from 'rxjs/operators';
import { SearchMedRegPersonInput, MedRegWbtDto, MedRegBewilligungDto, GetMedRegPersonDetailedOutput, LiaTypeServiceProxy, ExternalSystemEnum, ProfessionRegisterAffiliationServiceProxy, ProfessionRegisterAffiliationDto, SearchPsyRegPersonInput, SearchNaregPersonInput, SearchMedRegPersonOutput, SearchPsyRegPersonOutput, SearchNaregPersonOutput, GetNaregPersonDetailedOutput, GetPsyRegPersonDetailedOutput, GetPsyRegPersonOutput, GetNaregPersonOutput, MedRegWholePersonDetailedDto, PsyRegPersonDetailedDto, NaregPersonDetailedDto, NaregPersonDto, PsyRegPersonDto } from '@shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { LocalizationService } from 'abp-ng2-module';
import { HealthRegisterDiploma } from './dto/health-register-diploma';
import { HealthRegisterPostgraduateTitle } from './dto/health-register-postgraduate-title';
import { HealthRegisterPostgraduateTitlePrivateLaw } from './dto/health-register-postgraduate-title-private-law';
import { MedRegService } from './medreg.service';
import { PsyRegService } from './psyreg.service';
import { NaregService } from './nareg.service';

@Injectable()
export class HealthRegisterService {

    professionToHealthRegisterSystemAffiliations: ProfessionRegisterAffiliationDto[];
    healthRegisterSystem: ExternalSystemEnum;
    healthRegisterService: MedRegService | NaregService | PsyRegService;

    constructor(
        protected datePipe: DatePipe,
        protected localization: LocalizationService,
        private medRegService: MedRegService,
        private psyRegService: PsyRegService,
        private naregService: NaregService,
        private professionRegisterService: ProfessionRegisterAffiliationServiceProxy
    ) {
    }

    // Called from guard
    loadProfessionRegisterAffiliations(): Observable<ProfessionRegisterAffiliationDto[]> {
        return this.professionRegisterService.getAll().pipe(tap(affiliations => {
            this.professionToHealthRegisterSystemAffiliations = affiliations;
        }));
    }

    setHealthRegisterService(professionId: string) {

        this.healthRegisterSystem = this.professionToHealthRegisterSystemAffiliations.find(a => a.id === professionId).externalSystem;

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                this.healthRegisterService = this.medRegService;
                break;
            case ExternalSystemEnum.PsyReg:
                this.healthRegisterService = this.psyRegService;
                break;
            case ExternalSystemEnum.Nareg:
                this.healthRegisterService = this.naregService;
                break;
            default:
                this.healthRegisterService = null;
                break;
        }
    }

    search(professionId: string, gln: string): Observable<SearchMedRegPersonOutput | SearchPsyRegPersonOutput | SearchNaregPersonOutput | string> {

        this.setHealthRegisterService(professionId);

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                const medRegInput = new SearchMedRegPersonInput();
                medRegInput.gln = gln;
                return this.medRegService.search(medRegInput);
            case ExternalSystemEnum.PsyReg:
                const psyRegInput = new SearchPsyRegPersonInput();
                psyRegInput.gln = gln;
                return this.psyRegService.search(psyRegInput);
            case ExternalSystemEnum.Nareg:
                const naregInput = new SearchNaregPersonInput();
                naregInput.gln = gln;
                return this.naregService.search(naregInput);
            default:
                return of();
        }
    }

    getPerson(professionId: string, personId: number): Observable<MedRegWholePersonDetailedDto | PsyRegPersonDto | NaregPersonDto | string> {

        this.setHealthRegisterService(professionId);

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return this.medRegService.getPerson(personId);
            case ExternalSystemEnum.PsyReg:
                return this.psyRegService.getPerson(personId);
            case ExternalSystemEnum.Nareg:
                return this.naregService.getPerson(personId);
            default:
                return of();
        }
    }

    getPersonByProfessionAndGln(professionId: string, gln: string): Observable<MedRegWholePersonDetailedDto | PsyRegPersonDto | NaregPersonDto | string> {

        this.setHealthRegisterService(professionId);

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return this.medRegService.getPersonByGln(gln);
            case ExternalSystemEnum.PsyReg:
                return this.psyRegService.getPersonByGln(gln);
            case ExternalSystemEnum.Nareg:
                return this.naregService.getPersonByGln(gln);
            default:
                return of();
        }
    }

    getPersonDetailed(professionId: string, personId: number): Observable<MedRegWholePersonDetailedDto | PsyRegPersonDetailedDto | NaregPersonDetailedDto | string> {

        this.setHealthRegisterService(professionId);

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return this.medRegService.getPersonDetailed(personId);
            case ExternalSystemEnum.PsyReg:
                return this.psyRegService.getPersonDetailed(personId);
            case ExternalSystemEnum.Nareg:
                return this.naregService.getPersonDetailed(personId);
            default:
                return of();
        }
    }

    getPersonDetailedByProfessionAndGln(professionId: string, gln: string): Observable<MedRegWholePersonDetailedDto | PsyRegPersonDetailedDto | NaregPersonDetailedDto | string> {

        this.setHealthRegisterService(professionId);

        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return this.medRegService.getPersonDetailedByGln(gln);
            case ExternalSystemEnum.PsyReg:
                return this.psyRegService.getPersonDetailedByGln(gln);
            case ExternalSystemEnum.Nareg:
            //return this.naregService.getPersonDetailedB(gln);
            default:
                return of();
        }
    }

    getPersonDetailedByGln(gln: string): Observable<MedRegWholePersonDetailedDto | PsyRegPersonDetailedDto | NaregPersonDetailedDto | string> {
        // Since we don't know which register to query, we start with MedReg...
        this.healthRegisterSystem = ExternalSystemEnum.MedReg;
        this.healthRegisterService = this.medRegService;
        return this.healthRegisterService.getPersonDetailedByGln(gln).pipe(concatMap(medRegPerson => {
            if (medRegPerson) {
                return of(medRegPerson);
            }
            else {
                this.healthRegisterSystem = ExternalSystemEnum.PsyReg;
                this.healthRegisterService = this.psyRegService;
                return this.healthRegisterService.getPersonDetailedByGln(gln);
            }
        }));
    }

    get healthRegisterLabel(): string {
        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return 'MedReg';
            case ExternalSystemEnum.PsyReg:
                return 'PsyReg';
            case ExternalSystemEnum.Nareg:
                return 'Nareg';
            default:
                return null;
        }
    }

    getHealthRegisterLabelByProfession(professionId: string): string {
        this.healthRegisterSystem = this.professionToHealthRegisterSystemAffiliations.find(a => a.id === professionId).externalSystem;
        switch (this.healthRegisterSystem) {
            case ExternalSystemEnum.MedReg:
                return 'MedReg';
            case ExternalSystemEnum.PsyReg:
                return 'PsyReg';
            case ExternalSystemEnum.Nareg:
                return 'Nareg';
            default:
                return null;
        }
    }

    get SearchSuccessfulMessage(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.SearchSuccessfulMessage;
    }

    get SearchFailedMessageKey(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.SearchFailedMessageKey;
    }

    personWasFound(): boolean {
        return this.healthRegisterService.personWasFound();
    }

    get firstName(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.firstName;
    }

    get lastName(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.lastName;
    }

    get fullName(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.fullName;
    }

    get birthName(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.birthName;
    }

    get gender(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.gender;
    }

    get birthYear(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.birthYear;
    }

    get birthDate(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.birthDate;
    }

    get placeOfOrigin(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.placeOfOrigin;
    }

    get nationality1(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.nationality1;
    }

    get nationality2(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.nationality2;
    }

    get ahv(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.ahv;
    }

    get gln(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.gln;
    }

    get languageSkills(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.languageSkills;
    }

    get languageSkillsAsCommaSeparatedList(): string {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.languageSkillsAsCommaSeparatedList;
    }

    get queryTime(): DateTime {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.queryTime;
    }

    get diplomas(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.diplomas;
    }

    getProfessionDiplomaForComparison(professionId: string): HealthRegisterDiploma {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.getProfessionDiplomaForComparison(professionId);
    }

    getPostgraduateTitlesForComparison(): HealthRegisterPostgraduateTitle[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.getPostgraduateTitlesForComparison();
    }


    getPostgraduateTitlesPrivateLawForComparison(): HealthRegisterPostgraduateTitlePrivateLaw[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.getPostgraduateTitlesPrivateLawForComparison();
    }

    get postgraduateTitles(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.postgraduateTitles;
    }

//    private displayCorrectWbtDate(wbt: MedRegWbtDto): string {
//        return this.healthRegisterService.displayCorrectWbtDate(wbt);
//    }


    get normalLicences(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.normalLicences;
    }

    get dl90Licences(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.dl90Licences;
    }

    get cantonalLicenceRestrictions(): string[] {

        if (this.healthRegisterService == null) {
            return null;
        }

        return this.healthRegisterService.cantonalLicenceRestrictions;
    }

//    getLicences(): MedRegBewilligungDto[] {
//        return this.healthRegisterService.getLicences();
//    }

//    getLanguage(healthRegId: number): Promise<string> {
//        return this.healthRegisterService.getLanguage(healthRegId);
//    }

    get hasSensitiveData(): boolean {
        return this.healthRegisterService.hasSensitiveData;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return this.healthRegisterService.hasSensitiveDataCantonalLaw;
    }

//    private getDate(licence: MedRegBewilligungDto): DateTime {
//        return this.healthRegisterService.getDate(licence);
//    }

    getPostgraduateTitlesForPerson(personId: number): Observable<string[] | string> {
        return this.healthRegisterService.getPostgraduateTitlesForPerson(personId);
    }

//    clearCacheOnIdChange(healthRegId: number): void {
//        return this.healthRegisterService.clearCacheOnIdChange(healthRegId);
//    }
}
