import { DatePipe } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay, map } from 'rxjs/operators';
import { GetNaregPersonOutput, NaregServiceProxy, SearchNaregPersonInput, NaregLicenceDto, GetNaregPersonDetailedOutput, NaregPersonDetailedDto, NaregPersonDto } from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import { HealthRegisterBaseService } from './health-register-base.service';
import { DateTime } from 'luxon';
import { HealthRegisterPostgraduateTitle } from './dto/health-register-postgraduate-title';
import { HealthRegisterDiploma } from './dto/health-register-diploma';
import { HealthRegisterPostgraduateTitlePrivateLaw } from './dto/health-register-postgraduate-title-private-law';

@Injectable({
    providedIn: 'root',
})
export class NaregService extends HealthRegisterBaseService {

    latestNaregPerson: NaregPersonDetailedDto;

    constructor(
        protected datePipe: DatePipe,
        injector: Injector,
        private naregService: NaregServiceProxy,
    ) {
        super(datePipe, injector);
    }


    search(input: SearchNaregPersonInput) {
        return this.naregService.search(input);
    }

    getPerson(registerPersonId: number): Observable<NaregPersonDto | string> {
        if (this.latestNaregPerson !== undefined && this.latestNaregPerson.id === registerPersonId) {
            return of(this.latestNaregPerson).pipe(delay(1));
        }

        return this.naregService.get(registerPersonId)
            .pipe(map((output: GetNaregPersonOutput) => output.naregPerson))
            .pipe(tap((output: NaregPersonDetailedDto) => (this.latestNaregPerson = output))); // Cheating mapping NaregPersonDto to NaregPersonDetailedDto
    }

    getPersonByGln(gln: string): Observable<NaregPersonDto | string> {
        if (this.latestNaregPerson !== undefined && this.latestNaregPerson.gln === gln) {
            return of(this.latestNaregPerson).pipe(delay(1));
        }

        return this.naregService.getByGln(gln)
            .pipe(map((output: GetNaregPersonOutput) => output.naregPerson))
            .pipe(tap((output: NaregPersonDetailedDto) => (this.latestNaregPerson = output))); // Cheating mapping NaregPersonDto to NaregPersonDetailedDto
    }


    getPersonDetailed(registerPersonId: number): Observable<NaregPersonDetailedDto | string> {
        if (this.latestNaregPerson !== undefined && this.latestNaregPerson.id === registerPersonId &&
            this.latestNaregPerson.naregSensitiveData == undefined) {
            return of(this.latestNaregPerson).pipe(delay(1));
        }

        return this.naregService.getDetailed(registerPersonId)
            .pipe(map((output: GetNaregPersonDetailedOutput) => output.naregPerson))
            .pipe(tap((output: NaregPersonDetailedDto) => (this.latestNaregPerson = output)));
    }

    get SearchSuccessfulMessage(): string {
        return this.l('SearchSuccessfulNaregMessage');
    }

    get SearchFailedMessageKey(): string {
        return 'SearchFailedNaregMessage';
    }

    personWasFound(): boolean {
        return !!this.latestNaregPerson;
    }

    get postgraduateTitles(): string[] {
       return null;
    }

    get fullName(): string {
        var person = this.latestNaregPerson;
        var name = person?.firstName;

        if (person?.lastName) {
            if (name) {
                name = name + ' ' + person.lastName;
            } else {
                name = person.lastName;
            }
        }

        return name;
    }

    get firstName(): string {
        return this.latestNaregPerson?.firstName;
    }

    get lastName(): string {
        return this.latestNaregPerson?.lastName;
    }

    get birthName(): string {
        return this.latestNaregPerson?.originalName;
    }

    get placeOfOrigin(): string {
        return this.latestNaregPerson?.placeOfOrigin;
    }

    get gender(): string {
        return this.latestNaregPerson?.sexId.sironaId;
    }

    get nationality1(): string {
        return this.latestNaregPerson?.country1.sironaId;
    }

    get nationality2(): string {
        return this.latestNaregPerson?.country2.sironaId;
    }

    get birthYear(): string {
        return this.latestNaregPerson?.birthYear;
    }

    get birthDate(): string {
        return this.resolveDate(this.latestNaregPerson?.dateOfBirth);
    }

    get ahv(): string {
        return this.latestNaregPerson?.ahv;
    }

    get gln(): string {
        return this.latestNaregPerson?.gln;
    }

    get languageSkills(): string[] {
        return [];
    }

    get languageSkillsAsCommaSeparatedList(): string {
        return null;
    }

    get queryTime(): DateTime {
        return this.latestNaregPerson?.queryTime;
    }

    get diplomas(): string[] {
        const list: string[] = [];
        var diplome = this.latestNaregPerson?.naregDiploms;
        if (diplome) {
            for (let i = 0; i < diplome.length; i++) {
                if (diplome[i].profession.sironaId != null) {
                    list.push(
                        `${this.localization.localize(diplome[i].profession.sironaId, this.lSource)}, ${this.resolveDate(diplome[i].dateOfIssue)}`
                    );
                }
            }
        }
        return list;
    }

    get hasSensitiveData(): boolean {
        const noSensitiveDataCode = 10002;
        const sensitiveData = this.latestNaregPerson?.naregSensitiveData?.filter(x => x.isDeleted == false && x.code.healthRegisterId != noSensitiveDataCode);
        return sensitiveData?.length > 0;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return false;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestNaregPerson?.cantonalLicenceRestrictions;
    }


    getLanguage(naregId: number): Promise<string> {
        return new Promise((resolve) => {
            this.getPerson(naregId).subscribe((output: NaregPersonDetailedDto) => {
                const languageOfCorrespondence = output.correspondenceLanguage.sironaId;
                let language;
                if (languageOfCorrespondence == AppConsts.codes.language.german ||
                     languageOfCorrespondence == AppConsts.codes.language.french) {
                    language = languageOfCorrespondence;
                }
                resolve(language);
            });
        });
    }

    getLicences(): NaregLicenceDto[] {
        return this.latestNaregPerson?.naregLicences;
    }

    get normalLicences(): string[] {
        const list = [];
        let licences = this.getLicences();
        if (licences) {
            licences = licences.filter((l) => l.licenceType.healthRegisterId !== AppConsts.codes.external.nareg.licence.type.dla90);
            for (let i = 0; i < licences.length; i++) {
                list.push(
                    `${this.localization.localize(licences[i].profession.sironaId, this.lSource)},
                     ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                     ${this.resolveDate(licences[i].dateOfIssue)},
                     ${this.localization.localize(licences[i].status.sironaId, this.lSource)}`
                );
            }
        }
        return list;
    }

    get dl90Licences(): string[] {
        const list = [];
        let licences = this.getLicences();
        if (licences) {
            licences = licences.filter((l) => l.licenceType.healthRegisterId === AppConsts.codes.external.nareg.licence.type.dla90);
            for (let i = 0; i < licences.length; i++) {
                list.push(
                    `${this.localization.localize(licences[i].profession.sironaId, this.lSource)},
                     ${this.localization.localize(licences[i].canton.sironaId, this.lSource)},
                     ${this.resolveDate(licences[i].dateOfIssue)}`
                );
            }
        }
        return list;
    }

    getPostgraduateTitlesForComparison(): HealthRegisterPostgraduateTitle[] {
        return [];
    }

    getProfessionDiplomaForComparison(professionId: string): HealthRegisterDiploma {
        return null;
    }

    getPostgraduateTitlesPrivateLawForComparison(): HealthRegisterPostgraduateTitlePrivateLaw[] {
        return [];
    }


    getPostgraduateTitlesForPerson(registerPersonId: number): Observable<string[] | string> {
        return of([]);
    }

}
