import { Injectable, Injector } from '@angular/core';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';
import { AppConsts } from '@shared/AppConsts';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApplicationServiceProxy, ApplicationUploadFilesInput, CreateOrUpdateSplPostgraduateTitlesInput, CreateSplInput, CreateSplOutput, ExternalSystemEnum, GeneralFileDto, GeneralFileInfoDto, GetSplDocumentsInput, GetSplFileInput, GetSplOutput, LiaTypeServiceProxy, ProfessionRegisterAffiliationServiceProxy, ProfessionRequirementType, SplDocumentServiceProxy, SplDocumentsOutput, SplDto, SplNarcoticKnowledgeDto, SplNarcoticKnowledgeServiceProxy, SplPersonDto, SplPersonServiceProxy, SplPostgraduateTitleDto, SplPostgraduateTitleServiceProxy, SplServiceProxy, SplStatus } from './../../../shared/service-proxies/service-proxies';

export class SplInvoiceAddressDetail {
    name!: string | undefined;
    street!: string;
    streetNr!: string | undefined;
    postCode!: string;
    city!: string;
    email!: string;
    countryId!: string | undefined;

    constructor(data?: SplInvoiceAddressDetail) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)){
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}

@Injectable()
export class SplService extends ApplicationService {

    spl: SplDto;
    splResultDocuments: GeneralFileInfoDto[];
    healthRegisterSystem: ExternalSystemEnum;
    onlyPrivateAddressAllowed = false;

    private splsForPersonSubject = new ReplaySubject<SplDto[]>(1);
    readonly splsForPerson$ = this.splsForPersonSubject.asObservable();

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private professionConfigurationService: ProfessionConfigurationService,
        private professionRegisterService: ProfessionRegisterAffiliationServiceProxy,
        private liaService: LiaTypeServiceProxy,
        private splService: SplServiceProxy,
        private personService: SplPersonServiceProxy,
        private narcosticKnowledgeService: SplNarcoticKnowledgeServiceProxy,
        private postgraduateTitleService: SplPostgraduateTitleServiceProxy,
        private documentService: SplDocumentServiceProxy,
    ) {
        super(injector, codeService, appService);
    }

    loadSplsForPerson(gln: string): Observable<SplDto[]> {
        if (gln) {
            return this.splService.getSplsForPerson(gln).pipe(
                tap(spls => this.splsForPersonSubject.next(spls))
            );
        } else {
            this.splsForPersonSubject.next([]);
            return of([]);
        }
    }

    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaService.getExternalRegisterByProfessionId(professionId);
    }

    getActiveProfessions(): Observable<string[]> {
        return this.liaService.getActiveProfessions();
    }

    createSpl(input: CreateSplInput): Observable<CreateSplOutput> {
        return this.splService.createSpl(input).pipe(tap((output: CreateSplOutput) => {
            this.spl = null;
        }));
    }

    deleteSpl(): Observable<void> {
        return this.appService.deleteApplication(this.spl.application.caseId);
    }

    getSpl(caseId: string): Observable<GetSplOutput> {
        this.spl = null;
        return this.getApplication(caseId).pipe(switchMap(() => this.splService.getSpl(caseId).pipe(tap((output: GetSplOutput) => {
                this.spl = output.spl;

                return forkJoin([
                    this.professionConfigurationService.getRequirementByProfessionAndType(this.codes.profession.physician, ProfessionRequirementType.OnlyPrivateAddressAllowed),
                    this.professionRegisterService.getAll(),
                    of(output.spl)
                ]).subscribe(([result, affiliations, spl]) => {
                    this.onlyPrivateAddressAllowed = result.value === this.codes.yesNo.yes;
                    this.healthRegisterSystem = affiliations.find(a => a.id === this.codes.profession.physician).externalSystem;
                });
            }))));
    }

    getMySpls(): Observable<SplDto[]> {
        return this.splService.getMySpls();
    }

    getSplsForPerson(gln: string): Observable<SplDto[]> {
        return this.splService.getSplsForPerson(gln);
    }

    isInSplStatus(expectedStatus: SplStatus): boolean {
        return this.spl.statusId === expectedStatus;
    }

    getProfessionalLicencePersonIdForSpl(): Observable<number> {
        return this.splService.getProfessionalLicencePersonIdForSpl(this._caseId);
    }

    getEvaluationComments(): Observable<string> {
        return this.splService.getEvaluationComments(this._caseId);
    }

    releaseSpl(comment: string): Observable<void> {
        return this.splService.releaseSpl(this.spl.application.caseId, comment);
    }

    getNextStatus(status: SplStatus): Observable<SplStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.splService.getNextStatus(this._caseId, status).pipe(map(status => {
            // Authority goes to 'finish review' and not to 'release'
            if (isAuthority && status == SplStatus.Release) {
                return SplStatus.FinishReview;
            }
            return status;
        }));
    }

    getStepUrl(nextStep: SplStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    getFile(step: SplStatus, id: number): Observable<GeneralFileDto> {
        const input = new GetSplFileInput();
        input.caseId = this._caseId;
        input.step = step;
        input.id = id;
        return this.documentService.getFile(input);
    }

    getDocuments(step: SplStatus): Observable<SplDocumentsOutput> {
        const input = new GetSplDocumentsInput();
        input.caseId = this._caseId;
        input.step = step;
        return this.documentService.getDocuments(input);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: SplStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.spl.application.caseId;
    }

    // get professionId(): string {
    //     return this.spl?.professionId;
    // }

    //get medRegId(): number {
    //    return this.spl.person.medRegId;
    //}

    //get hasMedRegId(): boolean {
    //    return this.spl.person.medRegId != null;
    //}

    //get psyRegId(): number {
    //    return this.spl.person.psyRegId;
    //}

    //get hasPsyRegId(): boolean {
    //    return this.spl.person.psyRegId != null;
    //}

    //get naregId(): number {
    //    return this.spl.person.naregId;
    //}

    //get hasNaregId(): boolean {
    //    return this.spl.person.naregId != null;
    //}

    //get healthRegisterPersonId(): number {

    //    switch (this.healthRegisterSystem) {
    //        case ExternalSystemEnum.MedReg:
    //            return this.spl.person.medRegId;
    //        case ExternalSystemEnum.PsyReg:
    //            return this.spl.person.psyRegId;
    //        case ExternalSystemEnum.Nareg:
    //            return this.spl.person.naregId;
    //        default:
    //            return null;
    //    }
    //}

    get healthRegisterSystemId(): ExternalSystemEnum {
        return this.healthRegisterSystem;
    }

    setSplPersonStepToReview() {
        this.setStepToReview(SplStatus.Person);
    }

    getPerson(): Observable<SplPersonDto> {
        return this.personService.get(this.caseId);
    }

    createOrUpdatePerson(person: SplPersonDto): Observable<any> {
        this.setStepToReview(SplStatus.Person);
        return this.personService.createOrUpdate(this._caseId, person).pipe(tap((newStatusId: SplStatus) => {
            this.spl.statusId = newStatusId;
        }));
    }

    setSplNarcoticKnowledgeStepToReview() {
        this.setStepToReview(SplStatus.NarcoticKnowledge);
    }

    getNarcoticKnowledge(): Observable<SplNarcoticKnowledgeDto> {
        return this.narcosticKnowledgeService.get(this.caseId);
    }

    createOrUpdateNarcoticKnowledge(narcoticKnowledge: SplNarcoticKnowledgeDto): Observable<any> {
        this.setStepToReview(SplStatus.NarcoticKnowledge);
        return this.narcosticKnowledgeService.createOrUpdate(this._caseId, narcoticKnowledge).pipe(tap((newStatusId: SplStatus) => {
            this.spl.statusId = newStatusId;
        }));
    }

    setSplPostgraduateTitleStepToReview() {
        this.setStepToReview(SplStatus.PostgraduateTitle);
    }

    getPostgraduateTitles(): Observable<SplPostgraduateTitleDto[]> {
        return this.postgraduateTitleService.get(this.caseId);
    }

    createOrUpdatePostgraduateTitle(postgraduateTitle: CreateOrUpdateSplPostgraduateTitlesInput): Observable<any> {
        this.setStepToReview(SplStatus.PostgraduateTitle);
        return this.postgraduateTitleService.createOrUpdate(this._caseId, postgraduateTitle).pipe(tap((newStatusId: SplStatus) => {
            this.spl.statusId = newStatusId;
        }));
    }

    //createOrUpdateLiaPersonWithRegisterCheck(input: CreateOrUpdateLiaPersonWithRegisterCheckInput): Observable<CreateOrUpdateLiaPersonOutputDto> {
    //    this.setStepToReview(LiaStatus.Person);
    //    return this.liaPersonService.createOrUpdateLiaPersonWithRegisterCheck(input).pipe(tap((output: CreateOrUpdateLiaPersonOutputDto) => {
    //        this.lia.liaPerson = output.liaPerson;
    //    }));
    //}

    //acceptRegisterCheck(): Observable<LiaStatus> {
    //    this.setStepToReview(LiaStatus.Person);
    //    return this.liaPersonService.acceptRegisterCheck(this.caseId).pipe(tap((status: LiaStatus) => {
    //        this.lia.currentStep = status;
    //    }));
    //}

    checkDocuments(): Observable<SplStatus[]> {
        return this.splService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case SplStatus.Person:
                return 'person';
            case SplStatus.NarcoticKnowledge:
                return 'narcotic-knowledge';
            case SplStatus.PostgraduateTitle:
                return 'postgraduate-title';
            case SplStatus.Release:
                return 'release';
            case SplStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
