import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbpHttpInterceptor } from 'abp-ng2-module';

@Injectable()
export class SironaHttpInterceptor extends AbpHttpInterceptor {

    static sendWithCredentials: boolean = false;
    static urlsToSkipRequestHeaderNormalization: string[] = [];

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (SironaHttpInterceptor.sendWithCredentials) {
            request = request.clone({ withCredentials: true });
        }

        if (SironaHttpInterceptor.urlsToSkipRequestHeaderNormalization.some(x => x === request.url)) {
            return request;
        }

        return super.normalizeRequestHeaders(request);
    };
};
