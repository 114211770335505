import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { SironaCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
    BsDatepickerModule,
    BsDatepickerConfig,
    BsDaterangepickerConfig,
    BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { WizardGuard } from './wizard/wizard-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CountoModule } from 'angular2-counto';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { CodeCategoryComboComponent } from './code/code-category-combo.component';
import { CodeComboComponent } from './code/code-combo.component';
import { CardComponent } from './card/card.component';
import { WizardNavComponent } from './wizard/wizard-nav.component';
import { VisibilityServiceProxy } from '@shared/service-proxies/service-proxies';
import { FormControlComponent } from './form/form-control.component';
import { FormButtonsComponent } from './form/form-buttons.component';
import { CodeService } from './code/code.service';
import { WizardNavService } from './wizard/wizard-nav.service';
import { CustomValidationService } from './validation/custom-validation.service';
import { EmailNotConfirmedWarningComponent } from './email-not-confirmed-warning/email-not-confirmed-warning.component';
import { KeyPressValidationService } from '@app/shared/common/validation/key-press-validation.service';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessagesModule } from 'primeng/messages';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { RouterModule } from '@angular/router';
import { SimpleFileUploadComponent } from './simple-file-upload/simple-file-upload.component';
import { ModalFormDialogComponent } from './modal-form-dialog/modal-form-dialog.component';
import { FormEditSaveButtonToolbarComponent } from './form/form-button-toolbar.component';
import { AuthorityDocumentUploadComponent } from './document-upload/authority/authority-document-upload.component';
import { EntityFormComponent } from './edit-entity-form/edit-entity-form.component';
import { FileViewerDialogComponent } from './file-viewer-dialog/file-viewer-dialog.component';
import { SafePipeModule } from 'safe-pipe';
import { SubheaderModule } from './sub-header/subheader.module';
import { HealthRegisterPersonExtractComponent } from './health-register-person-extract/health-register-person-extract.component';
import { ApplicationModeService } from './wizard/application-mode.service';
import { DragAndDropFileUploadComponent } from './drag-and-drop-file-upload/drag-and-drop-file-upload.component';
import { YesNoDropdownComponent } from './yes-no-dropdown/yes-no-dropdown.component';
import { InputCustomValidationComponent } from './input-types/input-custom-validation/input-custom-validation.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        SironaCommonModule,
        TableModule,
        PaginatorModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        ProgressBarModule,
        MessagesModule,
        FileUploadModule,
        DialogModule,
        TooltipModule,
        RouterModule,
        SafePipeModule,
        SubheaderModule
    ],
    declarations: [
        CodeCategoryComboComponent,
        CodeComboComponent,
        FormControlComponent,
        FormButtonsComponent,
        CardComponent,
        WizardNavComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        EmailNotConfirmedWarningComponent,
        DocumentUploadComponent,
        SimpleFileUploadComponent,
        ModalFormDialogComponent,
        FormEditSaveButtonToolbarComponent,
        AuthorityDocumentUploadComponent,
        EntityFormComponent,
        FileViewerDialogComponent,
        HealthRegisterPersonExtractComponent,
        DragAndDropFileUploadComponent,
        YesNoDropdownComponent,
        InputCustomValidationComponent,
    ],
    exports: [
        CodeCategoryComboComponent,
        CodeComboComponent,
        FormControlComponent,
        FormButtonsComponent,
        CardComponent,
        WizardNavComponent,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        EmailNotConfirmedWarningComponent,
        DocumentUploadComponent,
        AuthorityDocumentUploadComponent,
        SimpleFileUploadComponent,
        ModalFormDialogComponent,
        EntityFormComponent,
        FormEditSaveButtonToolbarComponent,
        FileViewerDialogComponent,
        HealthRegisterPersonExtractComponent,
        DragAndDropFileUploadComponent,
        YesNoDropdownComponent,
        InputCustomValidationComponent,
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        VisibilityServiceProxy,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
    ],

    entryComponents: [
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                CodeService,
                WizardNavService,
                CustomValidationService,
                KeyPressValidationService,
                AppAuthService,
                AppRouteGuard,
                WizardGuard,
                ApplicationModeService
            ]
        };
    }
}
