import { Injectable } from '@angular/core';
import { CreateLiaStatusWizardStepInput,
  CreateOrUpdateProfessionRequirementInput,
  CreateProfessionRequirementGroupInput,
  LiaStatusWizardStepDto,
  LiaStatusWizardStepServiceProxy,
  CreateSlapStatusWizardStepInput,
    SlapStatusWizardStepDto,
    SlapStatusWizardStepServiceProxy,
    UpdateSlapStatusWizardStepsInput,

    ProfessionRegisterAffiliationDto,
    ProfessionRegisterAffiliationServiceProxy,
    ProfessionRequirementAlternativeDto,
    ProfessionRequirementDto,
    ProfessionRequirementGroupDto,
    ProfessionRequirementGroupServiceProxy,
    ProfessionRequirementServiceProxy,
    ProfessionRequirementType,
    UpdateLiaStatusWizardStepsInput,

    CreateEhcStatusWizardStepInput,
    EhcStatusWizardStepDto,
    EhcStatusWizardStepServiceProxy,
    UpdateEhcStatusWizardStepsInput,
    EhcPracticeConfigurationServiceProxy,
    CreateOrUpdateEhcPracticeConfigurationInput,
    EhcPracticeConfigurationDto, 
    ApplicationType} from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfessionConfigurationService {

  constructor(private professionRequirementProxy: ProfessionRequirementServiceProxy,
              private professionRegisterAffiliationProxy: ProfessionRegisterAffiliationServiceProxy,
              private professionRequirementGroupProxy: ProfessionRequirementGroupServiceProxy,
              private liaStatusWizardStepProxy: LiaStatusWizardStepServiceProxy,
              private ehcStatusWizardStepProxy: EhcStatusWizardStepServiceProxy,
              private slapStatusWizardStepProxy: SlapStatusWizardStepServiceProxy,
              private ehcPracticeConfigurationServiceProxy: EhcPracticeConfigurationServiceProxy) {  }

  getAffiliations(): Observable<ProfessionRegisterAffiliationDto[]> {
    return this.professionRegisterAffiliationProxy.getAll();
  }

  getAffiliationById(professionId: string): Observable<ProfessionRegisterAffiliationDto> {
    return this.professionRegisterAffiliationProxy.get(professionId);
  }

  updateAffiliation(professionAffiliationDto: ProfessionRegisterAffiliationDto): Observable<void> {
    return this.professionRegisterAffiliationProxy.update(professionAffiliationDto);
  }

  getRequirement(id: number): Observable<ProfessionRequirementDto> {
    return this.professionRequirementProxy.get(id);
  }

  getRequirementByProfessionAndType(professionId: string, requirementTypeId: ProfessionRequirementType): Observable<ProfessionRequirementDto | undefined> {
    return this.professionRequirementProxy.getRequirementByProfessionAndType(professionId, requirementTypeId);
  }

  createOrUpdateRequirement(professionDto: CreateOrUpdateProfessionRequirementInput): Observable<void> {
    return this.professionRequirementProxy.createOrUpdate(professionDto);
  }

  deleteRequirement(id: number): Observable<void> {
    return this.professionRequirementProxy.delete(id);
  }

  getAlternative(alternativeId: number): Observable<ProfessionRequirementAlternativeDto> {
    return this.professionRequirementGroupProxy.getAlternative(alternativeId);
  }

  createAlternative(groupId: number): Observable<number> {
    return this.professionRequirementGroupProxy.createAlternative(groupId);
  }

  deleteAlternative(alternativeId: number): Observable<void> {
    return this.professionRequirementGroupProxy.deleteAlternative(alternativeId);
  }

  getGroup(groupId: number): Observable<ProfessionRequirementGroupDto> {
    return this.professionRequirementGroupProxy.get(groupId);
  }

  createGroup(createProfessionRequirementGroupInput: CreateProfessionRequirementGroupInput): Observable<void> {
    return this.professionRequirementGroupProxy.create(createProfessionRequirementGroupInput);
  }

  updateGroup(id: number, description: string): Observable<void> {
    return this.professionRequirementGroupProxy.update(id, description);
  }

  deleteGroup(id: number): Observable<void> {
    return this.professionRequirementGroupProxy.delete(id);
  }

  getLiaWizardStepsByProfessionId(professionId: string, applicationType: ApplicationType): Observable<LiaStatusWizardStepDto[]> {
    return this.liaStatusWizardStepProxy.getAllByProfessionId(professionId, applicationType);
  }

  createLiaWizardStep(input: CreateLiaStatusWizardStepInput): Observable<LiaStatusWizardStepDto> {
    return this.liaStatusWizardStepProxy.create(input);
  }

  updateLiaWizardSteps(input: UpdateLiaStatusWizardStepsInput): Observable<void> {
    return this.liaStatusWizardStepProxy.updateMany(input);
  }

  deleteLiaWizardStep(step: number, professionId: string, applicationType: ApplicationType): Observable<void> {
    return this.liaStatusWizardStepProxy.delete(step, professionId, applicationType);
  }

  getEhcWizardStepsByProfessionId(professionId: string, applicationType: ApplicationType): Observable<EhcStatusWizardStepDto[]> {
    return this.ehcStatusWizardStepProxy.getAllByProfessionId(professionId, applicationType);
  }

  createEhcWizardStep(input: CreateEhcStatusWizardStepInput): Observable<EhcStatusWizardStepDto> {
    return this.ehcStatusWizardStepProxy.create(input);
  }

  updateEhcWizardSteps(input: UpdateEhcStatusWizardStepsInput): Observable<void> {
    return this.ehcStatusWizardStepProxy.updateMany(input);
  }

  deleteEhcWizardStep(step: number, professionId: string, applicationType: ApplicationType): Observable<void> {
    return this.ehcStatusWizardStepProxy.delete(step, professionId, applicationType);
  }

  getSlapWizardStepsByProfessionAndApplicationTypeId(professionId: string, applicationType: ApplicationType): Observable<SlapStatusWizardStepDto[]> {
    return this.slapStatusWizardStepProxy.getAllByProfessionIdAndApplicationType(professionId, applicationType);
  }

  createSlapWizardStep(input: CreateSlapStatusWizardStepInput): Observable<SlapStatusWizardStepDto> {
    return this.slapStatusWizardStepProxy.create(input);
  }

  updateSlapWizardSteps(input: UpdateSlapStatusWizardStepsInput): Observable<void> {
    return this.slapStatusWizardStepProxy.updateMany(input);
  }

    deleteSlapWizardStep(step: number, professionId: string, applicationType: ApplicationType): Observable<void> {
    return this.slapStatusWizardStepProxy.delete(step, professionId, applicationType);
  }

  getEhcPracticeConfiguration(professionId: string): Observable<EhcPracticeConfigurationDto> {
    return this.ehcPracticeConfigurationServiceProxy.get(professionId);
  }

  createOrUpdateEhcPracticeConfiguration(input: CreateOrUpdateEhcPracticeConfigurationInput): Observable<void> {
    return this.ehcPracticeConfigurationServiceProxy.createOrUpdate(input);
  }

  deleteEhcPracticeConfiguration(professionId: string): Observable<void> {
    return this.ehcPracticeConfigurationServiceProxy.delete(professionId);
    }

}
