import { Injectable } from '@angular/core';
import { ICode } from '@app/shared/common/code/code.interface';
import { CodeServiceProxy, CodeCategory, CodeSubcategory, CodeListDto } from '@shared/service-proxies/service-proxies';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppConsts } from '@shared/AppConsts';
import { Observable, of, Subject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

@Injectable()
export class CodeService {
    private halfMax = Number.MAX_VALUE / 2;
    allCodes: ICode[] = [];

    constructor(
        private localization: AppLocalizationService,
        private codeService: CodeServiceProxy) {
    }

    sortCode = (a: ICode, b: ICode) => {
        if (a.listIndex === b.listIndex){
            return a.translation.localeCompare(b.translation);
        }

        if ((a.listIndex === undefined ? this.halfMax : a.listIndex < 0 ? this.halfMax - a.listIndex : a.listIndex) <
                (b.listIndex === undefined ? this.halfMax : b.listIndex < 0 ? this.halfMax - b.listIndex : b.listIndex)) {
            return -1;
        } else {
            return 1;
        }
    };

    // Load all codes into a cache.
    loadCodes(): Observable<CodeListDto[]> {
        return this.codeService.getAllCodes().pipe(tap((codes) => {
            this.allCodes = codes.map(x => Object.assign({ translation: this.localization.l(x.id) } as ICode, x)).sort(this.sortCode);
        }));
    }

    getCodes(category: CodeCategory, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<ICode>> {
        return of(this.allCodes.filter(c => c.codeCategory === category && (codeSubcategory === CodeSubcategory.None || (c.codeSubcategory === codeSubcategory || c.codeCategory === null))));
    }

    getCodesSync(category: CodeCategory): Array<ICode> {
        return this.allCodes.filter(c => c.codeCategory === category);
    }

    getCodesForDropdown(category: CodeCategory, allOnTop?: boolean, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<SelectItem>> {
        return this.getCodes(category, codeSubcategory).pipe(
            map(result => {
                let codes = result.map(x => ({ value: x.id, label: x.translation, disabled: !x.isActive } as SelectItem));
                if (allOnTop) {
                    codes.unshift({ value: null, label: this.localization.l('All') });
                }

                return codes;
            })
        );
    }

    getActiveCodesForDropdown(category: CodeCategory, currentValue: string = null, allOnTop?: boolean, codeSubcategory: CodeSubcategory = CodeSubcategory.None): Observable<Array<SelectItem>> {
        return this.getCodes(category, codeSubcategory).pipe(
            map(result => {
                let codes = result.filter(c => c.isActive || c.id === currentValue).map(x => ({ value: x.id, label: x.translation, disabled: false } as SelectItem));
                if (allOnTop) {
                    codes.unshift({ value: null, label: this.localization.l('All') });
                }
                return codes;
            })
        );
    }

    getActiveCodesForDropdownSync(category: CodeCategory, currentValue: string, allOnTop?: boolean): Array<SelectItem> {
        let codes = this.getCodesSync(category).filter(c => c.isActive || c.id === currentValue).map(x => ({ value: x.id, label: x.translation, disabled: false } as SelectItem));

        if (allOnTop) {
            codes.unshift({ value: null, label: this.localization.l('All') });
        }

        return codes;
    }

    getEnumForDropdown(enumName: string, enumObj: object, allOnTop?: boolean): Array<SelectItem> {
        const array = new Array<SelectItem>();
        if (allOnTop) {
            array.push({ value: null, label: this.localization.l('All') });
        }
        Object.keys(enumObj).filter(x => isNaN(+x)).forEach(x => {
            array.push({ value: enumObj[x], label: this.localization.l(`${enumName}_${x}`) });
        });
        return array;
    }

    getTrueFalseDropdown(): Array<SelectItem> {
        return [
            { value: true, label: this.localization.l('Yes') },
            { value: false, label: this.localization.l('No') },
        ];
    }
}
