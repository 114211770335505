import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@app/shared/application/application.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlaService } from './ala.service';

@Injectable()
export class AlaGuard implements CanActivate {

    constructor(
        private alaService: AlaService,
        private applicationService: ApplicationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
       return this.alaService.getAla(this.applicationService.caseId).pipe(map(() => true));
    }
}
