import { Injectable, Injector } from '@angular/core';
import { ApplicationServiceProxy, ApplicationType, PeopleApplicationServiceProxy } from '@shared/service-proxies/service-proxies';
import { ApplicationService } from '../application/application.service';
import { CodeService } from '../common/code/code.service';

@Injectable()
export class PeopleApplicationService extends ApplicationService  {

    peopleApplicationTypes = [
        ApplicationType.Lia, ApplicationType.LiaRen, ApplicationType.Lia90Days,
        ApplicationType.Ehc, ApplicationType.EhcRen, ApplicationType.Ehc90Days,
        ApplicationType.Slap, ApplicationType.SlapRen,
        ApplicationType.Rla,
        ApplicationType.Tla,
        ApplicationType.Alap,
        ApplicationType.Abo,
        ApplicationType.Sub] as const;

    constructor(
        injector: Injector,
        appService: ApplicationServiceProxy,
        codeService: CodeService,
        private peopleApplicationServiceProxy: PeopleApplicationServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    getPeopleApplications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.peopleApplicationServiceProxy.getPeopleApplications(filter, sorting, maxResultCount, skipCount);
    }

    getProfessions() {
        return this.peopleApplicationServiceProxy.getProfessions();
    }

    getPeopleApplicationTypes(allOnTop: boolean) {
        return this.codeService.getEnumForDropdown('ApplicationType', ApplicationType, allOnTop)
            .filter(x => x.value === null || this.peopleApplicationTypes.includes(x.value));
    }
}
