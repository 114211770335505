import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, delay } from 'rxjs/operators';
import { MedRegServiceProxy, SearchMedRegPersonInput, MedRegWbtDto, MedRegBewilligungDto, GetMedRegPersonDetailedOutput, MedRegWholePersonDetailedDto, GetMedRegPersonOutput } from '@shared/service-proxies/service-proxies';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { HealthRegisterBaseService } from './health-register-base.service';
import { HealthRegisterDiploma } from './dto/health-register-diploma';
import { HealthRegisterPostgraduateTitle } from './dto/health-register-postgraduate-title';
import { HealthRegisterPostgraduateTitlePrivateLaw } from './dto/health-register-postgraduate-title-private-law';

@Injectable()
export class MedRegService extends HealthRegisterBaseService {

    latestMedRegPerson: MedRegWholePersonDetailedDto;

    constructor(
        protected datePipe: DatePipe,
        injector: Injector,
        private medRegService: MedRegServiceProxy
    ) {
        super(datePipe, injector);
    }

    search(input: SearchMedRegPersonInput) {
        return this.medRegService.search(input);
    }

    getPerson(registerPersonId: number): Observable<MedRegWholePersonDetailedDto | string> {
        if (
            this.latestMedRegPerson !== undefined &&
            this.latestMedRegPerson.person.id === registerPersonId
        ) {
            return of(this.latestMedRegPerson).pipe(delay(1));
        }

        return this.medRegService.get(registerPersonId)
            .pipe(map((output: GetMedRegPersonOutput) => (output.medRegWholePerson)))
            .pipe(tap((output: MedRegWholePersonDetailedDto) => (this.latestMedRegPerson = output)));
    }

    getPersonByGln(gln: string): Observable<MedRegWholePersonDetailedDto | string> {
        // Try get from cache
        if (!!this.latestMedRegPerson && this.latestMedRegPerson.person.gln === gln) {
            return of(this.latestMedRegPerson).pipe(delay(1));
        }

        // Get from server
        return this.medRegService.getByGln(gln)
            .pipe(map((output: GetMedRegPersonOutput) => (output.medRegWholePerson)))
            .pipe(tap((output: MedRegWholePersonDetailedDto) => (this.latestMedRegPerson = output)));
    }

    getPersonDetailed(registerPersonId: number): Observable<MedRegWholePersonDetailedDto | string> {
        if (
            this.latestMedRegPerson !== null &&
            this.latestMedRegPerson?.person.id === registerPersonId
        ) {
            return of(this.latestMedRegPerson).pipe(delay(1));
        }

        return this.medRegService.getDetailed(registerPersonId)
            .pipe(map((output: GetMedRegPersonDetailedOutput) => (output.medRegWholePerson)))
            .pipe(tap((output: MedRegWholePersonDetailedDto) => (this.latestMedRegPerson = output)));
    }

    getPersonDetailedByGln(gln: string): Observable<MedRegWholePersonDetailedDto | string> {

        // Try get from cache
        if (!!this.latestMedRegPerson && this.latestMedRegPerson.person.gln === gln) {
            return of(this.latestMedRegPerson).pipe(delay(1));
        }

        // Get from server
        return this.medRegService.getDetailedByGln(gln)
            .pipe(map((output: GetMedRegPersonDetailedOutput) => (output.medRegWholePerson)))
            .pipe(tap((output: MedRegWholePersonDetailedDto) => (this.latestMedRegPerson = output)));
    }

    get SearchSuccessfulMessage(): string {
        return this.l('SearchSuccessfulMedRegMessage');
    }

    get SearchFailedMessageKey(): string {
        return 'SearchFailedMedRegMessage';
    }

    personWasFound(): boolean {
        return !!this.latestMedRegPerson;
    }

    get fullName(): string {
        let person = this.latestMedRegPerson?.person;
        let name = person?.vorname;

        if (person?.nachname) {
            if (name) {
                name = name + ' ' + person.nachname;
            } else {
                name = person.nachname;
            }
        }

        if (person?.ledigerName) {
            if (name) {
                name = name + ' (' + person.ledigerName + ')';
            } else {
                name = '(' + person.ledigerName + ')';
            }
        }

        return name;
    }

    get nationality1(): string {
        return this.latestMedRegPerson?.person.nationalitaet1.sironaId;
    }

    get nationality2(): string {
        return this.latestMedRegPerson?.person?.nationalitaet2?.sironaId;
    }

    get birthYear(): string {
        return this.latestMedRegPerson?.person.geburtsjahr;
    }

    get ahv(): string {
        return this.latestMedRegPerson?.person.ahvNr;
    }

    get gender(): string {
        return this.latestMedRegPerson?.person.geschlecht.sironaId;
    }

    get gln(): string {
        return this.latestMedRegPerson?.person.gln;
    }

    get firstName(): string {
        return this.latestMedRegPerson?.person.vorname;
    }

    get lastName(): string {
        return this.latestMedRegPerson?.person.nachname;
    }

    get birthName(): string {
        return this.latestMedRegPerson?.person.ledigerName;
    }

    get placeOfOrigin(): string {
        return this.latestMedRegPerson?.person.heimatort;
    }

    get languageSkills(): string[] {
        if (this.latestMedRegPerson == null) return [];

        const languageSkills = this.latestMedRegPerson.person.sprachkompetenzen
                                        .filter(x => x.sironaId !== null)
                                        .map(x => x.sironaId);
        return languageSkills;
    }

    get languageSkillsAsCommaSeparatedList(): string {
        let skills = this.languageSkills;
        return skills?.length > 0 ? skills.map(s => this.localization.localize(s, this.lSource)).join(", ") : null;
    }

    get birthDate(): string {
        return this.datePipe.transform(this.latestMedRegPerson?.person.geburtsdatum.toJSDate());
    }

    get queryTime(): DateTime {
        return this.latestMedRegPerson?.queryTime;
    }

    get diplomas(): string[] {
        let list: string[] = [];
        let diplome = this.latestMedRegPerson?.medRegDiplome;
        if (diplome) {
            for (let i = 0; i < diplome.length; i++) {
                list.push(
                    this.localization.localize(diplome[i].art.sironaId, this.lSource) +
                        ', ' +
                        this.resolveDate(diplome[i].datumErteilung)
                );
            }
        }
        return list;
    }

    getProfessionDiplomaForComparison(professionId: string): HealthRegisterDiploma {
        let diplome = this.latestMedRegPerson?.medRegDiplome;
        if (diplome) {
            const d = diplome.find(x => x.art.sironaId === professionId);
            if (d == null) {
                return null;
            } else {
                const healthRegisterDiploma = new HealthRegisterDiploma();
                healthRegisterDiploma.professionId = d.art.sironaId;
                healthRegisterDiploma.typeId = d.typ.sironaId;
                healthRegisterDiploma.countryId = d.land.sironaId;
                healthRegisterDiploma.dateOfIssue = this.resolveDate(d.datumErteilung);
                healthRegisterDiploma.dateOfRecognition = this.resolveDate(d.datumAnerkennungCH);
                healthRegisterDiploma.placeOfIssue = d.ortErteilung;
                return healthRegisterDiploma;
            }
        }
    }

    getPostgraduateTitlesForComparison(): HealthRegisterPostgraduateTitle[] {
        let titles = this.latestMedRegPerson?.medRegWbts;
        if (titles) {
            const healthRegisterPostgraduateTitles: HealthRegisterPostgraduateTitle[] = [];
            for (let i = 0; i < titles.length; i++) {
                const healthRegisterPostgraduateTitle = new HealthRegisterPostgraduateTitle();
                healthRegisterPostgraduateTitle.titleId = titles[i].art.sironaId;
                healthRegisterPostgraduateTitle.typeId = titles[i].typ.sironaId;
                healthRegisterPostgraduateTitle.dateOfIssue = this.resolveDate(titles[i].datumErteilung);
                healthRegisterPostgraduateTitle.dateOfRecognition = this.resolveDate(titles[i].datumAnerkennungCH);
                healthRegisterPostgraduateTitle.placeOfIssue = titles[i].ortErteilung;
                healthRegisterPostgraduateTitle.countryId = titles[i].land.sironaId;
                healthRegisterPostgraduateTitles.push(healthRegisterPostgraduateTitle);
            }
            return healthRegisterPostgraduateTitles;
        }
    }

    getPostgraduateTitlesPrivateLawForComparison(): HealthRegisterPostgraduateTitlePrivateLaw[] {
        let titlesPrivateLaw = this.latestMedRegPerson?.medRegPwbs;
        if (titlesPrivateLaw) {
            const healthRegisterPostgraduateTitlesPrivateLaw: HealthRegisterPostgraduateTitlePrivateLaw[] = [];
            for (let i = 0; i < titlesPrivateLaw.length; i++) {
                const healthRegisterPostgraduateTitlePrivateLaw = new HealthRegisterPostgraduateTitlePrivateLaw();
                healthRegisterPostgraduateTitlePrivateLaw.name = titlesPrivateLaw[i].art.sironaId;
                healthRegisterPostgraduateTitlePrivateLaw.type = titlesPrivateLaw[i].typ.sironaId;
                healthRegisterPostgraduateTitlePrivateLaw.dateOfIssue = this.resolveDate(titlesPrivateLaw[i].datumErteilung);
                healthRegisterPostgraduateTitlesPrivateLaw.push(healthRegisterPostgraduateTitlePrivateLaw);
            }
            return healthRegisterPostgraduateTitlesPrivateLaw;
        }
    }

    get postgraduateTitles(): string[] {
        let list: string[] = [];
        let titles = this.latestMedRegPerson?.medRegWbts;
        if (titles) {
            for (let i = 0; i < titles.length; i++) {
                list.push(
                    this.resolveLanguage(titles[i].weiterbildungsartLabel) +
                        ', ' +
                        this.resolveLanguage(titles[i].weiterbildungstypLabel) +
                        ', ' +
                        this.displayCorrectWbtDate(titles[i])
                );
            }
        }
        return list;
    }

    private displayCorrectWbtDate(wbt: MedRegWbtDto): string {
        if (wbt.typ.healthRegisterId === AppConsts.codes.external.medreg.weiterbildungstitel.typ.annerkanterMebeko ||
            wbt.typ.healthRegisterId === AppConsts.codes.external.medreg.weiterbildungstitel.typ.gleichwertigerMebeko) {
                return this.resolveDate(wbt.datumAnerkennungCH);
            }

        return this.resolveDate(wbt.datumErteilung);
    }


    get normalLicences(): string[] {
        const list = [];
        const normalLicences = this.getLicences().filter((l) => l.typ.healthRegisterId !== 4004);

        if (normalLicences) {
            for (let i = 0; i < normalLicences.length; i++) {
                list.push(
                    `${this.localization.localize(normalLicences[i].diplomArt?.sironaId, this.lSource)},
                     ${this.localization.localize(normalLicences[i].kanton.sironaId, this.lSource)},
                     ${this.resolveDate(normalLicences[i].datumAktivitaet)},
                     ${this.localization.localize(normalLicences[i].status?.sironaId, this.lSource)},
                     ${this.resolveLanguage(normalLicences[i].aktivitaetLabel)}`
                );
            }
        }

        return list;
    }

    get dl90Licences(): string[] {
        const list = [];
        const dl90licences = this.getLicences().filter((l) => l.typ.healthRegisterId === 4004);

        if (dl90licences) {
            for (let i = 0; i < dl90licences.length; i++) {
                list.push(
                    `${this.localization.localize(dl90licences[i].diplomArt?.sironaId, this.lSource)},
                     ${this.localization.localize(dl90licences[i].kanton.sironaId, this.lSource)},
                     ${this.resolveDate(dl90licences[i].datumAktivitaet)},
                     ${this.localization.localize(dl90licences[i].status?.sironaId, this.lSource)},
                     ${this.resolveLanguage(dl90licences[i].aktivitaetLabel)}`
                );
            }
        }
        return list;
    }

    get cantonalLicenceRestrictions(): string[] {
        return this.latestMedRegPerson?.person.cantonalLicenceRestrictions;
    }

    getLicences(): MedRegBewilligungDto[] {
        var list: MedRegBewilligungDto[] = [];
        var diplomas = this.latestMedRegPerson?.medRegDiplome;
        if (diplomas) {
            for (let i = 0; i < diplomas.length; i++) {
                if (diplomas[i].medRegBewilligungen) {
                    for (let j = 0; j < diplomas[i].medRegBewilligungen.length; j++) {
                        list.push(diplomas[i].medRegBewilligungen[j]);
                    }
                }
            }
        }
        return list;
    }

    getLanguage(medRegId: number): Promise<string> {
        return new Promise((resolve) => {
            this.getPerson(medRegId).subscribe((output: MedRegWholePersonDetailedDto) => {
                let language;
                if (output.person.sprachkompetenzen.length === 1) {
                    const languageSkill = output.person.sprachkompetenzen[0].sironaId;
                    if (languageSkill === AppConsts.codes.languageSkill.german) {
                        language = AppConsts.codes.language.german;
                    } else if (languageSkill === AppConsts.codes.languageSkill.french) {
                        language = AppConsts.codes.language.french;
                    }
                }
                resolve(language);
            });
        });
    }

    get hasSensitiveData(): boolean {
        return this.latestMedRegPerson?.person.weitereInfoVorhanden;
    }

    get hasSensitiveDataCantonalLaw(): boolean {
        return this.latestMedRegPerson?.person.schuetzenswertGemKantRecht;
    }

    private getDate(licence: MedRegBewilligungDto): DateTime {
        if (licence.datumAktivitaet) {
            return licence.datumAktivitaet;
        }
        return licence.datumEntscheid;
    }

    resolveDate(date) {
        return (date == null) ? '-' : this.datePipe.transform(date.toJSDate());
    }

    getPostgraduateTitlesForPerson(registerPersonId: number): Observable<string[] | string> {
        return this.getPerson(registerPersonId).pipe(
            map((output: MedRegWholePersonDetailedDto) => {
                return output.medRegWbts.map((wbt: MedRegWbtDto) => wbt.art.sironaId);
            })
        );
    }

    clearCacheOnIdChange(medregId: number): void {
        if (this.latestMedRegPerson?.person?.id !== medregId) {
            this.latestMedRegPerson = null;
        }
    }
}
