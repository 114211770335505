import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    EmploymentChangeRequestDto, GetEmploymentsOutput, GetExistingEmploymentChangeRequestInfoOutput, GetExistingPersonChangeRequestInfoOutput, GetUserProfessionalLicencesOutput, IdentificationStatusDto, MySironaServiceProxy, UserContactDto, UserEmploymentDto, UserProfessionalLicenceDto
} from '@shared/service-proxies/service-proxies';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class UserService extends AppComponentBase {
    identificationStatus: IdentificationStatusDto;
    user: UserContactDto;
    employments: UserEmploymentDto[] = [];
    professionalLicences: UserProfessionalLicenceDto[] = [];

    constructor(
        injector: Injector,
        private mySironaService: MySironaServiceProxy
    ) {
        super(injector);
    }

    getUser(): Observable<UserContactDto> {
        return this.mySironaService.getContactInfo()
            .pipe(tap((output: UserContactDto) => {
                this.user = output;
            }));
    };

    getIdentificationStatus(refresh = false): Observable<IdentificationStatusDto> {
        if (!refresh && this.identificationStatus) {
            return of(this.identificationStatus);
        }

        return this.mySironaService.getIdentificationStatus()
            .pipe(tap(output =>
            this.identificationStatus = output.identificationStatus
        ), map((output) => output.identificationStatus));
    }

    getEmployments(): Observable<UserEmploymentDto[]> {
        return this.mySironaService.getEmployments()
            .pipe(tap((output: GetEmploymentsOutput) => {
                this.employments = output.items;
            }), map((output: GetEmploymentsOutput) => output.items));
    };

    getSponsorshipCount(): Observable<number> {
        return this.mySironaService.getSponsorshipCount().pipe(
                map(sponsorships => sponsorships.totalCount));
    }

    getEmploymentsCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getEmploymentsCount().pipe(
                map(employments => onlyActive ? employments.activeCount : employments.totalCount));
    }

    getProfessionalLicences(): Observable<UserProfessionalLicenceDto[]> {
        return this.mySironaService.getProfessionalLicences()
            .pipe(tap((output: GetUserProfessionalLicencesOutput) => {
                this.professionalLicences = output.items;
            }), map((output: GetUserProfessionalLicencesOutput) => output.items));
    }

    getLicencesCount(onlyActive: boolean): Observable<number> {
        return this.mySironaService.getProfessionalLicencesCount().pipe(
            map(lic => onlyActive ? lic.activeCount : lic.totalCount));
    }

    getSubmittedEmploymentChangeRequests(): Observable<EmploymentChangeRequestDto[]> {
        return this.mySironaService.getSubmittedEmploymentChangeRequests();
    }

    getExistingEmploymentChangeRequestInfo(): Observable<GetExistingEmploymentChangeRequestInfoOutput> {
        return this.mySironaService.getExistingEmploymentChangeRequestInfo();
    }

    getExistingPersonChangeRequestInfo(): Observable<GetExistingPersonChangeRequestInfoOutput> {
        return this.mySironaService.getExistingPersonChangeRequestInfo();
    }

    hasAnyApplicationOrSponsorship(): Observable<boolean> {
        if (abp.auth.isGranted('Pages.Applicant')) {
            return this.mySironaService.hasAnyApplicationOrSponsorship();
        }

        return of(false);
    }

    isEmailConfirmed(): Observable<boolean> {
        if (this.user) {
            return of(this.user.isEmailConfirmed);
        }

        return this.mySironaService.isUserEmailConfirmed();
    }
}
