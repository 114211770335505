import { EhcService } from './shared/services/ehc.service';
import { EhcGuard } from '@app/shared/services/ehc.guard';
import { LiaMaintenanceGuard } from './shared/services/lia-maintenance.guard';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { LinkAccountModalComponent } from '@app/shared/layout/link-account-modal.component';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { UserDelegationsModalComponent } from '@app/shared/layout/user-delegations-modal.component';
import { CreateNewUserDelegationModalComponent } from '@app/shared/layout/create-new-user-delegation-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { SmsVerificationModalComponent } from '@app/shared/layout/profile/sms-verification-modal.component';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';

import { ImpersonationService } from './admin/users/impersonation.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './shared/layout/themes/default/default-layout.component';
import { Theme2LayoutComponent } from './shared/layout/themes/theme2/theme2-layout.component';
import { Theme3LayoutComponent } from './shared/layout/themes/theme3/theme3-layout.component';
import { Theme4LayoutComponent } from './shared/layout/themes/theme4/theme4-layout.component';
import { Theme5LayoutComponent } from './shared/layout/themes/theme5/theme5-layout.component';
import { Theme6LayoutComponent } from './shared/layout/themes/theme6/theme6-layout.component';
import { Theme9LayoutComponent } from './shared/layout/themes/theme9/theme9-layout.component';
import { Theme7LayoutComponent } from './shared/layout/themes/theme7/theme7-layout.component';
import { Theme8LayoutComponent } from './shared/layout/themes/theme8/theme8-layout.component';
import { Theme10LayoutComponent } from './shared/layout/themes/theme10/theme10-layout.component';
import { Theme11LayoutComponent } from './shared/layout/themes/theme11/theme11-layout.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ThemeSelectionPanelComponent } from './shared/layout/theme-selection/theme-selection-panel.component';
import { ChatFriendListItemComponent } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatMessageComponent } from './shared/layout/chat/chat-message.component';
import { FooterComponent } from './shared/layout/footer.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { QuickThemeSelectionComponent } from './shared/layout/topbar/quick-theme-selection.component';
import { LanguageSwitchDropdownComponent } from './shared/layout/topbar/language-switch-dropdown.component';
import { LanguageSwitchComponent } from './shared/layout/topbar/language-switch.component';
import { ContactComponent } from './shared/layout/topbar/contact.component';
import { ChatToggleButtonComponent } from './shared/layout/topbar/chat-toggle-button.component';
import { SubscriptionNotificationBarComponent } from './shared/layout/topbar/subscription-notification-bar.component';
import { UserMenuComponent } from './shared/layout/topbar/user-menu.component';
import { DefaultBrandComponent } from './shared/layout/themes/default/default-brand.component';
import { Theme2BrandComponent } from './shared/layout/themes/theme2/theme2-brand.component';
import { Theme3BrandComponent } from './shared/layout/themes/theme3/theme3-brand.component';
import { Theme4BrandComponent } from './shared/layout/themes/theme4/theme4-brand.component';
import { Theme5BrandComponent } from './shared/layout/themes/theme5/theme5-brand.component';
import { Theme6BrandComponent } from './shared/layout/themes/theme6/theme6-brand.component';
import { Theme7BrandComponent } from './shared/layout/themes/theme7/theme7-brand.component';
import { Theme8BrandComponent } from './shared/layout/themes/theme8/theme8-brand.component';
import { Theme9BrandComponent } from './shared/layout/themes/theme9/theme9-brand.component';
import { Theme10BrandComponent } from './shared/layout/themes/theme10/theme10-brand.component';
import { Theme11BrandComponent } from './shared/layout/themes/theme11/theme11-brand.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper';
import { HeaderNotificationsComponent } from './shared/layout/notifications/header-notifications.component';
import { NotificationSettingsModalComponent } from './shared/layout/notifications/notification-settings-modal.component';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ActiveDelegatedUsersComboComponent } from './shared/layout/topbar/active-delegated-users-combo.component';

import { DefaultLogoComponent } from './shared/layout/themes/default/default-logo.component';
import { DatePipe } from '@angular/common';

// Metronic
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    // suppressScrollX: true
};

import { CoreModule } from '@metronic/app/core/core.module';
import { SessionTimeoutModalComponent } from './shared/common/session-timeout/session-timeout-modal-component';
import { SessionTimeoutComponent } from './shared/common/session-timeout/session-timeout.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MenuSearchBarComponent } from './shared/layout/nav/menu-search-bar/menu-search-bar.component';
import { NgxSpinnerModule, NgxSpinnerComponent } from 'ngx-spinner';
import { ScrollTopComponent } from './shared/layout/scroll-top.component';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { MedRegService } from '@app/shared/register/medreg.service';
import { SubheaderModule } from './shared/common/sub-header/subheader.module';
import { ChangeProfilePictureModalModule } from './shared/layout/profile/change-profile-picture-modal.module';
import { EnableTwoFactorAuthenticationModalComponent } from './shared/layout/profile/enable-two-factor-authentication-modal.component';
import { RecoveryCodesComponent } from './shared/layout/profile/recovery-codes.component';
import { VerifyCodeModalComponent } from './shared/layout/profile/verify-code-modal.component';
import { ViewRecoveryCodesModalComponent } from './shared/layout/profile/view-recovery-codes-modal.component';
import { ApplicationService } from './shared/application/application.service';
import { ApplicationGuard } from '@app/shared/application/application.guard';
import { LiaService } from './shared/services/lia.service';
import { SlapService } from './shared/services/slap.service';
import { AlaService } from './shared/services/ala.service';
import { ProfessionConfigurationService } from './shared/services/profession-configuration.service';
import { LiaGuard } from './shared/services/lia.guard';
import { SlapGuard } from './shared/services/slap.guard';
import { AlaGuard } from './shared/services/ala.guard';
import { CodesGuard } from './shared/services/codes.guard';
import { InvoiceGuard } from './shared/services/invoice.guard';
import { FirstLoginRouteGuard } from './shared/services/first-login-route.guard';
import { UserService } from './shared/services/user.service';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule} from 'primeng/message';
import { ServiceCategoryFilteringService } from './shared/services/service-category-filtering.service';
import { SavedRouteGuard } from '@app/shared/services/saved-route.guard';
import { AddressAutoCompleteService } from '@app/shared/services/address-auto-complete.service';
import { CategoryFilterGuard } from '@app/shared/services/category-filter.guard';
import { NaregService } from './shared/register/nareg.service';
import { PsyRegService } from './shared/register/psyreg.service';
import { PeopleApplicationService } from './shared/services/people-application.service';
import { HealthRegisterService } from './shared/register/health-register.service';
import { HealthRegisterGuard } from './shared/register/health-register.guard';
import { ClaService } from './shared/services/cla.service';
import { UidService } from './shared/services/uid.service';
import { SponsorshipApplicationService } from './shared/services/sponsorship-application.service';
import { AddressService } from './shared/services/address.service';
import { ClaGuard } from './shared/services/cla.guard';
import { MutGuard } from './shared/services/mut.guard';
import { MutService } from './shared/services/mut.service';
import { MutStepGuard } from './shared/services/mut-step.guard';
import { SplGuard } from './shared/services/spl.guard';
import { SplService } from './shared/services/spl.service';

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        Theme2LayoutComponent,
        Theme3LayoutComponent,
        Theme4LayoutComponent,
        Theme5LayoutComponent,
        Theme6LayoutComponent,
        Theme7LayoutComponent,
        Theme8LayoutComponent,
        Theme9LayoutComponent,
        Theme10LayoutComponent,
        Theme11LayoutComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        ScrollTopComponent,
        LinkedAccountsModalComponent,
        UserDelegationsModalComponent,
        CreateNewUserDelegationModalComponent,
        LinkAccountModalComponent,
        ChangePasswordModalComponent,
        MySettingsModalComponent,
        SmsVerificationModalComponent,
        EnableTwoFactorAuthenticationModalComponent,
        RecoveryCodesComponent,
        VerifyCodeModalComponent,
        ViewRecoveryCodesModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ThemeSelectionPanelComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        QuickThemeSelectionComponent,
        LanguageSwitchDropdownComponent,
        LanguageSwitchComponent,
        ContactComponent,
        ChatToggleButtonComponent,
        SubscriptionNotificationBarComponent,
        UserMenuComponent,
        DefaultBrandComponent,
        Theme2BrandComponent,
        Theme3BrandComponent,
        Theme4BrandComponent,
        Theme5BrandComponent,
        Theme6BrandComponent,
        Theme7BrandComponent,
        Theme8BrandComponent,
        Theme9BrandComponent,
        Theme10BrandComponent,
        Theme11BrandComponent,
        SessionTimeoutModalComponent,
        SessionTimeoutComponent,
        MenuSearchBarComponent,
        ActiveDelegatedUsersComboComponent,
        DefaultLogoComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FileUploadModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        PerfectScrollbarModule,
        CoreModule,
        TextMaskModule,
        ImageCropperModule,
        AutoCompleteModule,
        NgxSpinnerModule,
        AppBsModalModule,
        SubheaderModule,
        ChangeProfilePictureModalModule,
        MessagesModule,
        MessageModule
    ],
    providers: [
        DatePipe,
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            multi: true,
        },
        MedRegService,
        NaregService,
        PsyRegService,
        HealthRegisterService,
        ApplicationService,
        ApplicationGuard,
        MessageService,
        AddressService,
        PeopleApplicationService,
        SponsorshipApplicationService,
        ProfessionConfigurationService,
        LiaService,
        SlapService,
        SplService,
        AlaService,
        EhcService,
        ClaService,
        MutService,
        LiaGuard,
        SlapGuard,
        SplGuard,
        AlaGuard,
        EhcGuard,
        ClaGuard,
        MutGuard,
        MutStepGuard,
        CodesGuard,
        InvoiceGuard,
        HealthRegisterGuard,
        LiaMaintenanceGuard,
        FirstLoginRouteGuard,
        UserService,
        CategoryFilterGuard,
        ServiceCategoryFilteringService,
        SavedRouteGuard,
        AddressAutoCompleteService,
        UidService
    ],
    entryComponents: [NgxSpinnerComponent],
    exports: []
})
export class AppModule {}

function appInitializerFactory() {
    return () => {

        const url = new URL(location.href);
        const params = url.searchParams;

        if (params.has('t')) {
            params.delete('t');
            window.location.href = url.toString();
        }
    };
}
