import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressSearchDto, AddressSearchInput, AddressSearchOutput, AddressServiceProxy, FormAddressSearchInput } from './../../../shared/service-proxies/service-proxies';

@Injectable()
export class AddressService {

    constructor(private addressSearchServiceProxy: AddressServiceProxy) {}

    gridSearch(input: AddressSearchInput): Observable<AddressSearchOutput> {
        return this.addressSearchServiceProxy.gridSearch(input);
    }

    formSearch(input: FormAddressSearchInput): Observable<AddressSearchOutput> {
        return this.addressSearchServiceProxy.formSearch(input);
    }
}
