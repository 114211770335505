import { Injectable, Injector } from '@angular/core';
import { ApplicationServiceProxy, ApplicationType, SponsorshipApplicationServiceProxy } from '@shared/service-proxies/service-proxies';
import { ApplicationService } from '../application/application.service';
import { CodeService } from '../common/code/code.service';

@Injectable()
export class SponsorshipApplicationService extends ApplicationService  {

    constructor(
        injector: Injector,
        appService: ApplicationServiceProxy,
        codeService: CodeService,
        private serviceProxy: SponsorshipApplicationServiceProxy
    ) {
        super(injector, codeService, appService);
    }
}
