import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationService } from '@app/shared/application/application.service';
import { ClaService } from './cla.service';

@Injectable()
export class ClaGuard implements CanActivate {

    constructor(
        private claService: ClaService,
        private applicationService: ApplicationService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
       return this.claService.getCla(this.applicationService.caseId).pipe(map(() => true));
    }
}
