import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationModeService {
    private applicationModeSubject = new BehaviorSubject<string>(AppConsts.codes.applicationMode.readOnly);
    applicationMode$ = this.applicationModeSubject.asObservable();

    setApplicationMode(mode: string) {
        this.applicationModeSubject.next(mode);
    }

    clearApplicationMode() {
        this.applicationModeSubject.next(AppConsts.codes.applicationMode.readOnly);
    }

    getApplicationMode(): string {
        return this.applicationModeSubject.value;
    }
}
