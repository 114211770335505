import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { InvoiceService } from './invoice.service';
import { PermissionCheckerService } from 'abp-ng2-module';

@Injectable()
export class InvoiceGuard implements CanActivate {

    constructor(
        private readonly invoiceService: InvoiceService,
        private readonly permission: PermissionCheckerService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this.permission.isGranted('Pages.Authority.Invoices') || this.permission.isGranted('Pages.Authority.Dossier.Invoices')) {
            return this.invoiceService.getProducts().pipe(map(() => true));
        }

        return of(true);
    }
}
