import { Injectable, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AddressAutocompleteServiceProxy, KeyValuePairOfStringString } from '@shared/service-proxies/service-proxies';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable()
export class AddressAutoCompleteService extends AppComponentBase {

    postCode: string = null;
    cities: KeyValuePairOfStringString[] = [];
    citySuggestions: string[] = [];
    streets: KeyValuePairOfStringString[] = [];
    streetSuggestions: string[] = [];
    houseNumbers: KeyValuePairOfStringString[] = [];
    houseNumberSuggestions: string[] = [];
    mainFormReference: FormGroup;

    constructor(
        private addressAutocompleteServiceProxy: AddressAutocompleteServiceProxy,
        injector: Injector
    ) {
        super(injector);
    }

    searchCities(postCode: string) {
        if (postCode && postCode.length === 4) {
            this.addressAutocompleteServiceProxy.getCitiesByPostCode(postCode).subscribe(cities => {
                this.citySuggestions = cities.map((city: KeyValuePairOfStringString) => city.value);
                this.cities = cities;
                this.postCode = postCode;
            });
        } else {
            this.citySuggestions = [];
        }
    }

    onCitySelected(city: string) {
        this.mainFormReference.get('postCode').setValue(this.postCode);
        this.mainFormReference.get('city').setValue(city);
        this.postCode = null;
        this.citySuggestions = [];
    }

    onCitySelectedForIndex(city: string, index: number, groupName: string) {
        const previousEmployments = this.mainFormReference.get(groupName) as FormArray;
        const previousemployment = previousEmployments.at(index) as FormGroup;
        previousemployment.get('postCode').setValue(this.postCode);
        previousemployment.get('city').setValue(city);
        this.postCode = null;
        this.citySuggestions = [];
    }

    searchStreets(query: string, index: number = undefined, groupName: string = undefined) {
        let city = '';
        if (index != undefined) {
            const previousEmployments = this.mainFormReference.get(groupName) as FormArray;
            const previousemployment = previousEmployments.at(index) as FormGroup;
            city = previousemployment.get('city').value;
        } else {
            city = this.mainFormReference.get('city').value;
        }

        let cityId = Number(this.cities.find(c => c.value === city)?.key);
        if (isNaN(cityId)) {
            this.streets = [];
            this.streetSuggestions = [];
            return;
        }

        this.addressAutocompleteServiceProxy.getStreetsByCity(cityId, query).subscribe((streets: KeyValuePairOfStringString[]) => {
            this.streets = streets;
            this.streetSuggestions = streets.map(x => x.value);
        });
    }

    onStreetSelected(selection: string) {

    }

    searchHouseNumbers(query: string, index: number = undefined, groupName: string = undefined) {
        let street = '';
        if (index != undefined) {
            const previousEmployments = this.mainFormReference.get(groupName) as FormArray;
            const previousemployment = previousEmployments.at(index) as FormGroup;
            street = previousemployment.get('street').value;
        } else {
            street = this.mainFormReference.get('street').value;
        }

        let streetId = Number(this.streets.find(str => str.value === street)?.key);
        if (isNaN(streetId)) {
            this.houseNumbers = [];
            this.houseNumberSuggestions = [];
            return;
        }

        this.addressAutocompleteServiceProxy.getHouseNumbersByStreetId(streetId, query).subscribe((houseNumbers: KeyValuePairOfStringString[]) => {
            this.houseNumbers = houseNumbers;
            this.houseNumberSuggestions = houseNumbers.map(x => x.value);
        });
    }

    onHouseNumberSelected(selection: KeyValuePairOfStringString) {

    }
}
