import { Injectable } from '@angular/core';
import { ContactDto, CreateOrUpdatePersonDto, CreatePersonAndLinkToEmploymentInput, CreatePersonFromEmploymentInfoDto, CreatePersonFromHealthRegisterInfoDto, GetPersonDossierSearchOutput, GetPersonDto, InvoiceAddressDto, PersonDossierServiceProxy, PersonDossierSummaryDto, PersonLicenceDto, PersonSearchDto } from '@shared/service-proxies/service-proxies';
import { ReplaySubject, tap, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class PersonDossierService {

    person: PersonDossierSummaryDto = null;
    private personDossierSummarySubject = new ReplaySubject<PersonDossierSummaryDto>(1);
    readonly personDossierSummary$ = this.personDossierSummarySubject.asObservable();

    constructor(
        private personDossierService: PersonDossierServiceProxy
    ) {}

    clearPerson() {
        this.person = undefined;
        this.personDossierSummarySubject.next(undefined);
    }

    GetId(): number {
        return this.person?.id;
    }

    CreatePerson(personDto: CreateOrUpdatePersonDto): Observable<GetPersonDto> {
        return this.personDossierService.createPerson(personDto);
    }

    CreatePersonAndContactFromEmploymentInfo(personAndContact: CreatePersonFromEmploymentInfoDto): Observable<GetPersonDto> {
        return this.personDossierService.createPersonAndContactFromEmploymentInfo(personAndContact);
    }

    CreatePersonFromHealthRegisterInfo(personDto: CreatePersonFromHealthRegisterInfoDto): Observable<GetPersonDto> {
        return this.personDossierService.createPersonFromHealthRegisterInfo(personDto);
    }

    CreatePersonAndLinkToDeployment(input: CreatePersonAndLinkToEmploymentInput): Observable<GetPersonDto> {
        return this.personDossierService.createPersonAndLinkToEmployment(input);
    }

    UpdatePerson(personDto: CreateOrUpdatePersonDto): Observable<GetPersonDto> {
        return this.personDossierService.updatePerson(this.person.id, personDto).pipe(
            tap((person) => this.loadPersonDossierSummary(person.id).subscribe())
        );
    }

    public Search(input: PersonSearchDto): Observable<GetPersonDossierSearchOutput> {
        return this.personDossierService.search(input);
    }

    public GetPersonDossiers(filter: string, sorting: string, maxResultCount: number, skipCount: number): Observable<GetPersonDossierSearchOutput> {
        return this.personDossierService.getPersonDossiers(filter, sorting, maxResultCount, skipCount);
    }

    public loadPersonDossierSummary(id: number): Observable<PersonDossierSummaryDto> {
        return this.personDossierService.getPersonSummary(id).pipe(
            tap((person: PersonDossierSummaryDto) => {
                this.person = person;
                this.personDossierSummarySubject.next(person);
            })
        );
    }

    public GetPersonCoreData(): Observable<GetPersonDto> {
        return this.personDossierService.getPerson(this.person.id);
    }

    public UpdatePersonCoreData(input: CreateOrUpdatePersonDto) {
        return this.personDossierService.updatePerson(input.id, input).pipe(
            tap((person) => this.loadPersonDossierSummary(person.id).subscribe())
        );
    }

    public get personDossierBasePath(): string {
        return this.person ? `/app/authority/person-dossier/${this.person.id}/` : null;
    }

    public GetPersonContact() {
        return this.personDossierService.getContact(this.person.id);
    }

    public UpdateContact(input: ContactDto) {
        return this.personDossierService.putContact(this.person.id, input);
    }

    public GetPersonInvoiceAddress(): Observable<InvoiceAddressDto> {
        return this.personDossierService.getInvoiceAddress(this.person.id);
    }

    public getPersonLicences(personId: number): Observable<PersonLicenceDto[]> {
        return this.personDossierService.getPersonLicences(personId);
    }

    public UpdateInvoiceAddress(input: InvoiceAddressDto) {
        return this.personDossierService.putInvoiceAddress(this.person.id, input);
    }
}
