import { Injectable, Injector } from '@angular/core';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';
import { AppConsts } from '@shared/AppConsts';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ApplicationServiceProxy, ApplicationUploadFilesInput, CreateSlapInput, CreateSlapOutput, ExternalSystemEnum, GeneralFileDto, GeneralFileInfoDto, GetSlapDocumentsInput, GetSlapFileInput, GetSlapOutput, LiaTypeServiceProxy, ProfessionRegisterAffiliationServiceProxy, ProfessionRequirementType, SlapBusinessDto, SlapBusinessServiceProxy, SlapDocumentServiceProxy, SlapDocumentsOutput, SlapDto, SlapInvoiceAddressDto, SlapInvoiceAddressServiceProxy, SlapPersonDto, SlapPersonServiceProxy, SlapPracticeDto, SlapPracticeServiceProxy, SlapProductPreparationDto, SlapProductPreparationServiceProxy, SlapRangeOfProductsDto, SlapRangeOfProductsServiceProxy, SlapServiceProxy, SlapStatus } from './../../../shared/service-proxies/service-proxies';

export class SlapInvoiceAddressDetail {
    name!: string | undefined;
    street!: string;
    streetNr!: string | undefined;
    postCode!: string;
    city!: string;
    email!: string;
    countryId!: string | undefined;

    constructor(data?: SlapInvoiceAddressDetail) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

@Injectable()
export class SlapService extends ApplicationService {

    slap: SlapDto;
    slapResultDocuments: GeneralFileInfoDto[];
    healthRegisterSystem: ExternalSystemEnum;
    onlyPrivateAddressAllowed = false;

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private professionConfigurationService: ProfessionConfigurationService,
        private professionRegisterService: ProfessionRegisterAffiliationServiceProxy,
        private liaService: LiaTypeServiceProxy,
        private slapService: SlapServiceProxy,
        private personService: SlapPersonServiceProxy,
        private businessService: SlapBusinessServiceProxy,
        private practiceService: SlapPracticeServiceProxy,
        private rangeOfProductsService: SlapRangeOfProductsServiceProxy,
        private productPreparationService: SlapProductPreparationServiceProxy,
        private invoiceAddressService: SlapInvoiceAddressServiceProxy,
        private documentService: SlapDocumentServiceProxy,
    ) {
        super(injector, codeService, appService);
    }

    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaService.getExternalRegisterByProfessionId(professionId);
    }

    getActiveProfessions(): Observable<string[]> {
        return this.liaService.getActiveProfessions();
    }

    createSlap(input: CreateSlapInput): Observable<CreateSlapOutput> {
        return this.slapService.createSlap(input).pipe(tap((output: CreateSlapOutput) => {
            this.slap = null;
        }));
    }

    deleteSlap(): Observable<void> {
        return this.appService.deleteApplication(this.slap.application.caseId);
    }

    getSlap(caseId: string): Observable<GetSlapOutput> {
        this.slap = null;
        return this.getApplication(caseId).pipe(switchMap(() => {

            return this.slapService.getSlap(caseId).pipe(tap((output: GetSlapOutput) => {
                this.slap = output.slap;

                return forkJoin([
                    this.professionConfigurationService.getRequirementByProfessionAndType(this.slap.professionId, ProfessionRequirementType.OnlyPrivateAddressAllowed),
                    this.professionRegisterService.getAll(),
                    of(output.slap)
                ]).subscribe(([result, affiliations, slap]) => {
                    this.onlyPrivateAddressAllowed = result.value == this.codes.yesNo.yes;
                    this.healthRegisterSystem = affiliations.find(a => a.id === slap.professionId).externalSystem;
                });
            }));
        }));
    }

    getMySlaps(): Observable<SlapDto[]> {
        return this.slapService.getMySlaps();
    }

    getSlapsForPerson(gln: string): Observable<SlapDto[]> {
        return this.slapService.getSlapsForPerson(gln);
    }

    isInSlapStatus(expectedStatus: SlapStatus): boolean {
        return this.slap.statusId === expectedStatus;
    }

    getProfessionalLicencePersonIdForSlap(): Observable<number> {
        return this.slapService.getProfessionalLicencePersonIdForSlap(this._caseId);
    }

    getEvaluationComments(): Observable<string> {
        return this.slapService.getEvaluationComments(this._caseId);
    }

    releaseSlap(comment: string): Observable<void> {
        return this.slapService.releaseSlap(this.slap.application.caseId, comment);
    }

    getNextStatus(status: SlapStatus): Observable<SlapStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.slapService.getNextStatus(this._caseId, status).pipe(map(status => {
            // Authority goes to 'finish review' and not to 'release'
            if (isAuthority && status == SlapStatus.Release) {
                return SlapStatus.FinishReview;
            }
            return status;
        }));
    }

    getStepUrl(nextStep: SlapStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    getFile(step: SlapStatus, id: number): Observable<GeneralFileDto> {
        const input = new GetSlapFileInput();
        input.caseId = this._caseId;
        input.step = step;
        input.id = id;
        return this.documentService.getFile(input);
    }

    getDocuments(step: SlapStatus): Observable<SlapDocumentsOutput> {
        const input = new GetSlapDocumentsInput();
        input.caseId = this._caseId;
        input.step = step;
        return this.documentService.getDocuments(input);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: SlapStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.slap.application.caseId;
    }

    get professionId(): string {
        return this.slap?.professionId;
    }

    //get medRegId(): number {
    //    return this.slap.person.medRegId;
    //}

    //get hasMedRegId(): boolean {
    //    return this.slap.person.medRegId != null;
    //}

    //get psyRegId(): number {
    //    return this.slap.person.psyRegId;
    //}

    //get hasPsyRegId(): boolean {
    //    return this.slap.person.psyRegId != null;
    //}

    //get naregId(): number {
    //    return this.slap.person.naregId;
    //}

    //get hasNaregId(): boolean {
    //    return this.slap.person.naregId != null;
    //}

    //get healthRegisterPersonId(): number {

    //    switch (this.healthRegisterSystem) {
    //        case ExternalSystemEnum.MedReg:
    //            return this.slap.person.medRegId;
    //        case ExternalSystemEnum.PsyReg:
    //            return this.slap.person.psyRegId;
    //        case ExternalSystemEnum.Nareg:
    //            return this.slap.person.naregId;
    //        default:
    //            return null;
    //    }
    //}

    get healthRegisterSystemId(): ExternalSystemEnum {
        return this.healthRegisterSystem;
    }

    setSlapPersonStepToReview() {
        this.setStepToReview(SlapStatus.Person);
    }

    getPerson(): Observable<SlapPersonDto> {
        return this.personService.get(this.caseId);
    }

    createOrUpdatePerson(person: SlapPersonDto): Observable<any> {
        this.setStepToReview(SlapStatus.Person);
        return this.personService.createOrUpdate(this._caseId, person).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    getBusiness(): Observable<SlapBusinessDto> {
        return this.businessService.get(this.caseId);
    }

    createOrUpdateBusiness(business: SlapBusinessDto): Observable<any> {
        this.setStepToReview(SlapStatus.Business);
        return this.businessService.createOrUpdate(this._caseId, business).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    getPractice(): Observable<SlapPracticeDto> {
        return this.practiceService.get(this.caseId);
    }

    createOrUpdatePractice(practice: SlapPracticeDto): Observable<any> {
        this.setStepToReview(SlapStatus.Practice);
        return this.practiceService.createOrUpdate(this._caseId, practice).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    getRangeOfProducts(): Observable<SlapRangeOfProductsDto> {
        return this.rangeOfProductsService.get(this.caseId);
    }

    updateRangeOfProducts(rangeOfProducts: SlapRangeOfProductsDto): Observable<any> {
        this.setStepToReview(SlapStatus.RangeOfProducts);
        return this.rangeOfProductsService.createOrUpdate(this._caseId, rangeOfProducts).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    getProductPreparation(): Observable<SlapProductPreparationDto> {
        return this.productPreparationService.get(this.caseId);
    }

    createOrUpdateProductPreparation(productPreparation: SlapProductPreparationDto): Observable<any> {
        this.setStepToReview(SlapStatus.ProductPreparation);
        return this.productPreparationService.createOrUpdate(this._caseId, productPreparation).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    //createOrUpdateLiaPersonWithRegisterCheck(input: CreateOrUpdateLiaPersonWithRegisterCheckInput): Observable<CreateOrUpdateLiaPersonOutputDto> {
    //    this.setStepToReview(LiaStatus.Person);
    //    return this.liaPersonService.createOrUpdateLiaPersonWithRegisterCheck(input).pipe(tap((output: CreateOrUpdateLiaPersonOutputDto) => {
    //        this.lia.liaPerson = output.liaPerson;
    //    }));
    //}

    //acceptRegisterCheck(): Observable<LiaStatus> {
    //    this.setStepToReview(LiaStatus.Person);
    //    return this.liaPersonService.acceptRegisterCheck(this.caseId).pipe(tap((status: LiaStatus) => {
    //        this.lia.currentStep = status;
    //    }));
    //}

    getInvoiceAddress(): Observable<SlapInvoiceAddressDto> {
        return this.invoiceAddressService.get(this.caseId);
    }

    createOrUpdateInvoiceAddress(invoiceAddress: SlapInvoiceAddressDto): Observable<any> {
        this.setStepToReview(SlapStatus.InvoiceAddress);
        return this.invoiceAddressService.createOrUpdate(this._caseId, invoiceAddress).pipe(tap((newStatusId: SlapStatus) => {
            this.slap.statusId = newStatusId;
        }));
    }

    checkDocuments(): Observable<SlapStatus[]> {
        return this.slapService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case SlapStatus.Person:
                return 'person';
            case SlapStatus.Business:
                return 'business';
            case SlapStatus.Practice:
                return 'practice';
            case SlapStatus.RangeOfProducts:
                return 'range-of-products';
            case SlapStatus.ProductPreparation:
                return 'product-preparation';
            case SlapStatus.InvoiceAddress:
                return 'invoice-address';
            case SlapStatus.Release:
                return 'release';
            case SlapStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
