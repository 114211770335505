import { Injectable, Injector } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { tap, map, delay, switchMap } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ClaStatus, ClaServiceProxy, ApplicationServiceProxy, GeneralFileInfoDto, GeneralFileDto, GeneralDocumentGroupDto, SponsorshipApplicationServiceProxy,
    CorporateLicenceType, ClaDto, GetClaOutput, GetClaCorporateLicenceDto, ApplicationType, ApplicationUploadFilesInput, GetClaFileInput, GetClaSubItemDocumentsInput,
    ClaInvoiceAddressDto, CreateOrUpdateClaInvoiceAddressInput, ClaInvoiceAddressServiceProxy,
    ClaSponsorshipDto, CreateOrUpdateClaSponsorshipInput, ClaSponsorshipServiceProxy,
    ClaSponsorshipDirectorDto, UpdateClaSponsorshipDirectorsInput, UpdateClaSponsorshipDirectorsOutput,
    ClaDocumentServiceProxy, GetClaDocumentsInput, ClaDocumentsOutput,
    ClaBusinessServiceProxy, ClaBusinessDto, UpdateClaBusinessesInput, UpdateClaBusinessesOutput,
    UpdateClaBusinessDirectorsInput, UpdateClaBusinessDirectorsOutput,
    ClaMedicalFieldDirectorServiceProxy, UpdateClaMedicalFieldDirectorInput, UpdateClaMedicalFieldDirectorOutput, ClaMedicalFieldDirectorDto,
    ClaMedicalFieldDirectorRepresentativeServiceProxy, UpdateClaMedicalFieldDirectorRepresentativeInput, UpdateClaMedicalFieldDirectorRepresentativeOutput, ClaMedicalFieldDirectorRepresentativeDto,
    ClaRangeOfServicesServiceProxy, UpdateClaRangeOfServicesInput, UpdateClaRangeOfServicesOutput,
    ClaInfrastructureServiceProxy, UpdateClaInfrastructureInput, UpdateClaInfrastructureOutput,
    ClaAssistantPhysicianServiceProxy, UpdateClaAssistantPhysiciansOutput, UpdateClaAssistantPhysiciansInput, ClaAssistantPhysicianDto,
    ClaMedicalFieldPersonServiceProxy, ClaMedicalFieldPersonDto, UpdateClaMedicalFieldPersonsInput, UpdateClaMedicalFieldPersonsOutput,
    ClaPersonelServiceProxy, UpdateClaPersonelInput, UpdateClaPersonelOutput,
    ClaTreatmentServiceProxy, UpdateClaTreatmentInput, UpdateClaTreatmentOutput,
    ClaNursingBedsServiceProxy, UpdateClaNursingBedsInput, UpdateClaNursingBedsOutput,
    ClaFloorsServiceProxy, UpdateClaFloorsInput, UpdateClaFloorsOutput,
    ClaAidingResourcesServiceProxy, UpdateClaAidingResourcesInput, UpdateClaAidingResourcesOutput,
    ClaSanitaryFacilitiesServiceProxy, UpdateClaSanitaryFacilitiesInput, UpdateClaSanitaryFacilitiesOutput,
    ClaSecurityServiceProxy, UpdateClaSecurityInput, UpdateClaSecurityOutput,
    ClaQualityServiceProxy, UpdateClaQualityInput, UpdateClaQualityOutput,
    ClaServiceAreasServiceProxy, UpdateClaServiceAreasInput, UpdateClaServiceAreasOutput,
    ClaDrugChambersServiceProxy, UpdateClaDrugChambersInput, UpdateClaDrugChambersOutput,
    ClaWheelchairAccessibilityServiceProxy, UpdateClaWheelchairAccessibilityInput, UpdateClaWheelchairAccessibilityOutput,
    SlasRangeOfProductsServiceProxy, SlasProductPreparationServiceProxy, UpdateSlasRangeOfProductsInput, UpdateSlasRangeOfProductsOutput, UpdateSlasProductPreparationOutput, UpdateSlasProductPreparationInput, UpdateSlasLocalDrugSalesInput, UpdateSlasLocalDrugSalesOutput, SlasLocalDrugSalesServiceProxy, SlasDrugProductionServiceProxy, SlasDrugProductionThirdPartyServiceProxy, UpdateSlasDrugProductionOutput, UpdateSlasDrugProductionInput, UpdateSlasDrugProductionThirdPartyInput, UpdateSlasDrugProductionThirdPartyOutput, OlaOnlineDrugSalesServiceProxy } from './../../../shared/service-proxies/service-proxies';
import { forEach } from 'lodash-es';

export class ClaInvoiceAddressDetail {
    name!: string | undefined;
    street!: string;
    streetNr!: string | undefined;
    postCode!: string;
    city!: string;
    email!: string;
    countryId!: string | undefined;

    constructor(data?: ClaInvoiceAddressDetail) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)){
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }
}

@Injectable()
export class ClaService extends ApplicationService {

    cla: ClaDto;
    claResultDocuments: GeneralFileInfoDto[];
    sponsorshipApplicationTypes = [ApplicationType.Cla, ApplicationType.ClaRen, ApplicationType.Slas, ApplicationType.SlasRen, ApplicationType.Ola, ApplicationType.OlaRen, ApplicationType.ClaEhc, ApplicationType.Alas] as const;
    private clasForSponsorshipSubject = new ReplaySubject<ClaDto[]>(1);
    readonly clasForSponsorship$ = this.clasForSponsorshipSubject.asObservable();

    constructor(
        injector: Injector,
        codeService: CodeService,
        private appService: ApplicationServiceProxy,
        private claService: ClaServiceProxy,
        private documentService: ClaDocumentServiceProxy,
        private sponsorshipService: ClaSponsorshipServiceProxy,
        private medicalFieldDirectorService: ClaMedicalFieldDirectorServiceProxy,
        private medicalFieldDirectorRepresentativeService: ClaMedicalFieldDirectorRepresentativeServiceProxy,
        private businessService: ClaBusinessServiceProxy,
        private rangeOfServicesService: ClaRangeOfServicesServiceProxy,
        private treatmentService: ClaTreatmentServiceProxy,
        private nursingBedsService: ClaNursingBedsServiceProxy,
        private floorsService: ClaFloorsServiceProxy,
        private sanitaryFacilitiesService: ClaSanitaryFacilitiesServiceProxy,
        private securityService: ClaSecurityServiceProxy,
        private drugChambersService: ClaDrugChambersServiceProxy,
        private aidingResourcesService: ClaAidingResourcesServiceProxy,
        private wheelchairAccessibilityService: ClaWheelchairAccessibilityServiceProxy,
        private qualityService: ClaQualityServiceProxy,
        private personelService: ClaPersonelServiceProxy,
        private infrastructureService: ClaInfrastructureServiceProxy,
        private serviceAreasService: ClaServiceAreasServiceProxy,
        private medicalFieldPersonService: ClaMedicalFieldPersonServiceProxy,
        private assistantPhysicianService: ClaAssistantPhysicianServiceProxy,
        private rangeOfProductsService: SlasRangeOfProductsServiceProxy,
        private productPreparationService: SlasProductPreparationServiceProxy,
        private localDrugSalesService: SlasLocalDrugSalesServiceProxy,
        private drugProductionService: SlasDrugProductionServiceProxy,
        private drugProductionThirdPartyService: SlasDrugProductionThirdPartyServiceProxy,
        private onlineDrugSalesService: OlaOnlineDrugSalesServiceProxy,
        private invoiceAddressService: ClaInvoiceAddressServiceProxy,
        private serviceProxy: SponsorshipApplicationServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    //getFailedLiaForProfessionAndGln(caseId: string): Observable<GetLiaOutput> {
    //    return this.claService.getFailedLiaForProfessionAndGln(caseId);
    //}

    getActiveSponsorshipTypes(): Observable<string[]> {
        return this.claService.getActiveSponsorshipTypes();
    }

    getSponsorshipApplications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.serviceProxy.getSponsorshipApplications(filter, sorting, maxResultCount, skipCount);
    }

    getSponsorshipApplicationTypes(allOnTop: boolean) {
        return this.codeService.getEnumForDropdown('ApplicationType', ApplicationType, allOnTop)
            .filter(x => x.value === null || this.sponsorshipApplicationTypes.includes(x.value));
    }

    getCorporateLicenceTypes(allOnTop: boolean) {
        return this.codeService.getEnumForDropdown('CorporateLicenceType', CorporateLicenceType, allOnTop);
    }

    //createCla(input: CreateClaTypeInput): Observable<CreateClaTypeOutput> {
    //    return this.claService.createCla(input).pipe(tap((output: CreateClaOutput) => {
    //        this.cla = null;
    //    }));
    //}

    deleteCla(): Observable<void> {
        return this.appService.deleteApplication(this.cla.application.caseId);
    }

    getCla(caseId: string): Observable<GetClaOutput> {
        this.cla = null;
        return this.getApplication(caseId).pipe(switchMap(() => {

            return this.claService.getCla(caseId).pipe(tap((output: GetClaOutput) => {
                this.cla = output.cla;
            }));
        }));
    }

    getMyClas(): Observable<ClaDto[]> {
        return this.claService.getMyClas();
    }

    loadClasForSponsorship(uid: string): Observable<ClaDto[]> {
        if (this.isEmpty(uid)) {
            return of([]);
        }

        return this.claService.getClasForSponsorship(uid).pipe(
            tap(clas => this.clasForSponsorshipSubject.next(clas))
        );
    }

    //isInLiaStatus(expectedStatus: LiaStatus): boolean {
    //    return this.lia.currentStep === expectedStatus;
    //}

    getCorporateLicenceForCla(): Observable<GetClaCorporateLicenceDto> {
        return this.claService.getCorporateLicenceForCla(this._caseId);
    }

    getEvaluationComments(): Observable<string> {
        return this.claService.getEvaluationComments(this._caseId);
    }

    releaseCla(comment: string): Observable<void> {
        return this.claService.releaseCla(this.cla.application.caseId, comment);
    }

    getNextStatus(status: ClaStatus): Observable<ClaStatus> {

        return this.claService.getNextStatus(this._caseId, status).pipe(map(status => {

            // Authority goes to 'finish review' and not to 'release'
            let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
            if (isAuthority && status === ClaStatus.Release) {
                return ClaStatus.FinishReview;
            }

            return status;
        }));
    }

    getStepUrl(nextStep: ClaStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }


    getFiles(step: ClaStatus, hasCorporateLicenceInAnotherCantonId: string): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaDocumentsInput({ caseId: this._caseId, step: step, hasCorporateLicenceInAnotherCantonId: hasCorporateLicenceInAnotherCantonId, hasProfessionalLicenceId: undefined, hasAppliedForProfessionalLicenceId: undefined});
        return this.documentService.getDocuments(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    getFile(step: ClaStatus, id: number): Observable<GeneralFileDto> {
        return this.documentService.getFile(new GetClaFileInput({ caseId: this._caseId, step: step, id: id }));
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: ClaStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.cla.application.caseId;
    }

    get sponsorshipTypeId(): string {
        return this.cla?.sponsorshipTypeId;
    }

    get name(): string {
        return this.cla.sponsorship?.name;
    }

    getSponsorship(): Observable<ClaSponsorshipDto> {
        return of(this.cla.sponsorship).pipe(delay(0));
    }

    createOrUpdateClaSponsorship(input: CreateOrUpdateClaSponsorshipInput): Observable<void> {

        this.setStepToReview(ClaStatus.Sponsorship);
        return this.sponsorshipService.createOrUpdateSponsorship(input).pipe(tap((status: ClaStatus) => {
            this.cla.sponsorship = input.sponsorship;
            this.cla.statusId = status;
        }))
            .pipe(map((status: ClaStatus) => null));
    }

    getMedicalFieldDirector(): Observable<ClaMedicalFieldDirectorDto> {
        if (this.cla.medicalFieldDirector) {
            return of(this.cla.medicalFieldDirector).pipe(delay(0));
        }

        return this.createPlaceholderMedicalFieldDirector(this.caseId);
    }

    createPlaceholderMedicalFieldDirector(caseId: string): Observable<ClaMedicalFieldDirectorDto> {
        return this.medicalFieldDirectorService.createPlaceholderMedicalFieldDirector(caseId);
    }

    updateMedicalFieldDirector(input: UpdateClaMedicalFieldDirectorInput): Observable<void> {

        this.setStepToReview(ClaStatus.MedicalFieldDirector);
        return this.medicalFieldDirectorService.updateMedicalFieldDirector(input).pipe(tap((output: UpdateClaMedicalFieldDirectorOutput) => {
            this.cla.medicalFieldDirector = input.medicalFieldDirector;
            this.cla.statusId = output.status;
            this.cla.medicalFieldDirector = output.medicalFieldDirector;
        }))
            .pipe(map((output: UpdateClaMedicalFieldDirectorOutput) => null));
    }

    getMedicalFieldDirectorFiles(hasAppliedForProfessionalLicenceId: string): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaDocumentsInput({ caseId: this._caseId, step: ClaStatus.MedicalFieldDirector, hasCorporateLicenceInAnotherCantonId: undefined, hasProfessionalLicenceId: undefined, hasAppliedForProfessionalLicenceId: hasAppliedForProfessionalLicenceId });
        return this.documentService.getMedicalFieldDirectorDocuments(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadMedicalFieldDirectorFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadMedicalFieldDirectorFiles(input);
    }

    getMedicalFieldDirectorRepresentative(): Observable<ClaMedicalFieldDirectorRepresentativeDto> {
        if (this.cla.medicalFieldDirectorRepresentative) {
            return of(this.cla.medicalFieldDirectorRepresentative).pipe(delay(0));
        }

        return this.createPlaceholderMedicalFieldDirectorRepresentative(this.caseId);
    }

    createPlaceholderMedicalFieldDirectorRepresentative(caseId: string): Observable<ClaMedicalFieldDirectorRepresentativeDto> {
        return this.medicalFieldDirectorRepresentativeService.createPlaceholderMedicalFieldDirectorRepresentative(caseId);
    }

    updateMedicalFieldDirectorRepresentative(input: UpdateClaMedicalFieldDirectorRepresentativeInput): Observable<void> {

        this.setStepToReview(ClaStatus.MedicalFieldDirectorRepresentative);
        return this.medicalFieldDirectorRepresentativeService.updateMedicalFieldDirectorRepresentative(input).pipe(tap((output: UpdateClaMedicalFieldDirectorRepresentativeOutput) => {
            this.cla.medicalFieldDirectorRepresentative = input.medicalFieldDirectorRepresentative;
            this.cla.statusId = output.status;
            this.cla.medicalFieldDirectorRepresentative = output.medicalFieldDirectorRepresentative;
        }))
            .pipe(map((output: UpdateClaMedicalFieldDirectorRepresentativeOutput) => null));
    }

    getMedicalFieldDirectorRepresentativeFiles(hasAppliedForProfessionalLicenceId: string): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaDocumentsInput({ caseId: this._caseId, step: ClaStatus.MedicalFieldDirectorRepresentative, hasCorporateLicenceInAnotherCantonId: undefined, hasProfessionalLicenceId: undefined, hasAppliedForProfessionalLicenceId: hasAppliedForProfessionalLicenceId });
        return this.documentService.getMedicalFieldDirectorRepresentativeDocuments(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadMedicalFieldDirectorRepresentativeFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadMedicalFieldDirectorRepresentativeFiles(input);
    }

    getSponsorshipDirectors(): Observable<ClaSponsorshipDirectorDto[]> {
        return of(this.cla.sponsorshipDirectors);
    }

    createPlaceholderSponsorshipDirector(caseId: string): Observable<number> {
        return this.sponsorshipService.createPlaceholderSponsorshipDirector(caseId);
    }

    updateSponsorshipDirectors(input: UpdateClaSponsorshipDirectorsInput): Observable<void> {
        this.setStepToReview(ClaStatus.SponsorshipDirectors);
        return this.sponsorshipService.updateSponsorshipDirectors(input).pipe(tap((output: UpdateClaSponsorshipDirectorsOutput) => {
            this.cla.sponsorshipDirectors = output.directors;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getSponsorshipDirectorFiles(directorId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.SponsorshipDirectors;
        input.subItemId = directorId;
        return this.documentService.getSponsorshipDirectorFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadSponsorshipDirectorFiles(directorId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadSponsorshipDirectorFiles(directorId, input);
    }

    getBusinesses(): Observable<ClaBusinessDto[]> {
        return of(this.cla.businesses);
    }

    createPlaceholderBusiness(caseId: string): Observable<number> {
        return this.businessService.createPlaceholderBusiness(caseId);
    }

    updateBusinesses(input: UpdateClaBusinessesInput): Observable<void> {
        this.setStepToReview(ClaStatus.Business);
        return this.businessService.updateBusinesses(input).pipe(tap((output: UpdateClaBusinessesOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getBusinessFiles(businessId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.Business;
        input.subItemId = businessId;
        return this.documentService.getBusinessFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadBusinessFiles(businessId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadBusinessFiles(businessId, input);
    }

    createPlaceholderBusinessDirector(caseId: string, businessId: number): Observable<number> {
        return this.businessService.createPlaceholderBusinessDirector(caseId, businessId);
    }

    updateBusinessDirectors(input: UpdateClaBusinessDirectorsInput): Observable<void> {
        this.setStepToReview(ClaStatus.BusinessDirectors);
        input.directors.forEach(dir => {
            if (dir.isSameAsMedicalFieldManagementId) {
                dir.levelOfEmployment = this.cla.medicalFieldDirector.levelOfEmployment;
                dir.phone1 = this.cla.medicalFieldDirector.phone1;
                dir.phone2 = this.cla.medicalFieldDirector.phone2;
                dir.email = this.cla.medicalFieldDirector.email;
            }
        });
        return this.businessService.updateBusinessDirectors(input).pipe(tap((output: UpdateClaBusinessDirectorsOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getBusinessDirectorFiles(directorId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.BusinessDirectors;
        input.subItemId = directorId;
        return this.documentService.getBusinessDirectorFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadBusinessDirectorFiles(directorId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadBusinessDirectorFiles(directorId, input);
    }

    createPlaceholderRangeOfServices(caseId: string, businessId: number): Observable<number> {
        return this.rangeOfServicesService.createPlaceholderRangeOfServices(caseId, businessId);
    }

    updateRangeOfServices(input: UpdateClaRangeOfServicesInput): Observable<void> {
        this.setStepToReview(ClaStatus.RangeOfServices);
        return this.rangeOfServicesService.updateRangeOfServicesList(input).pipe(tap((output: UpdateClaRangeOfServicesOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }


    getRangeOfServicesFiles(rangeOfServicesId: number, hasXRayMachineId: string, hasOperatingTheatresId: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.RangeOfServices;
        input.subItemId = rangeOfServicesId;
        input.hasXRayMachineId = hasXRayMachineId;
        input.hasOperatingTheatresId = hasOperatingTheatresId;
        return this.documentService.getRangeOfServicesFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadRangeOfServicesFiles(rangeOfServicesId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadRangeOfServicesFiles(rangeOfServicesId, input);
    }


    createPlaceholderTreatments(caseId: string, businessId: number): Observable<number> {
        return this.treatmentService.createPlaceholderTreatment(caseId, businessId);
    }

    updateTreatments(input: UpdateClaTreatmentInput): Observable<void> {
        this.setStepToReview(ClaStatus.Treatment);
        return this.treatmentService.updateTreatmentList(input).pipe(tap((output: UpdateClaTreatmentOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderNursingBeds(caseId: string, businessId: number): Observable<number> {
        return this.nursingBedsService.createPlaceholderNursingBeds(caseId, businessId);
    }

    updateNursingBeds(input: UpdateClaNursingBedsInput): Observable<void> {
        this.setStepToReview(ClaStatus.NursingBeds);
        return this.nursingBedsService.updateNursingBedsList(input).pipe(tap((output: UpdateClaNursingBedsOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderFloors(caseId: string, businessId: number): Observable<number> {
        return this.floorsService.createPlaceholderFloors(caseId, businessId);
    }

    updateFloors(input: UpdateClaFloorsInput): Observable<void> {
        this.setStepToReview(ClaStatus.Floors);
        return this.floorsService.updateFloorsList(input).pipe(tap((output: UpdateClaFloorsOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderWheelchairAccessibility(caseId: string, businessId: number): Observable<number> {
        return this.wheelchairAccessibilityService.createPlaceholderWheelchairAccessibility(caseId, businessId);
    }

    updateWheelchairAccessibility(input: UpdateClaWheelchairAccessibilityInput): Observable<void> {
        this.setStepToReview(ClaStatus.WheelchairAccessibility);
        return this.wheelchairAccessibilityService.updateWheelchairAccessibilityList(input).pipe(tap((output: UpdateClaWheelchairAccessibilityOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderSecurity(caseId: string, businessId: number): Observable<number> {
        return this.securityService.createPlaceholderSecurity(caseId, businessId);
    }

    updateSecurity(input: UpdateClaSecurityInput): Observable<void> {
        this.setStepToReview(ClaStatus.Security);
        return this.securityService.updateSecurityList(input).pipe(tap((output: UpdateClaSecurityOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getSecurityFiles(securityId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.Security;
        input.subItemId = securityId;
        return this.documentService.getSecurityFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadSecurityFiles(securityId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadSecurityFiles(securityId, input);
    }

    createPlaceholderAidingResources(caseId: string, businessId: number): Observable<number> {
        return this.aidingResourcesService.createPlaceholderAidingResources(caseId, businessId);
    }

    updateAidingResources(input: UpdateClaAidingResourcesInput): Observable<void> {
        this.setStepToReview(ClaStatus.AidingResources);
        return this.aidingResourcesService.updateAidingResourcesList(input).pipe(tap((output: UpdateClaAidingResourcesOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderDrugChambers(caseId: string, businessId: number): Observable<number> {
        return this.drugChambersService.createPlaceholderDrugChambers(caseId, businessId);
    }

    updateDrugChambers(input: UpdateClaDrugChambersInput): Observable<void> {
        this.setStepToReview(ClaStatus.DrugChambers);
        return this.drugChambersService.updateDrugChambersList(input).pipe(tap((output: UpdateClaDrugChambersOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderSanitaryFacilities(caseId: string, businessId: number): Observable<number> {
        return this.sanitaryFacilitiesService.createPlaceholderSanitaryFacilities(caseId, businessId);
    }

    updateSanitaryFacilities(input: UpdateClaSanitaryFacilitiesInput): Observable<void> {
        this.setStepToReview(ClaStatus.NursingBeds);
        return this.sanitaryFacilitiesService.updateSanitaryFacilitiesList(input).pipe(tap((output: UpdateClaSanitaryFacilitiesOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderQuality(caseId: string, businessId: number): Observable<number> {
        return this.qualityService.createPlaceholderQuality(caseId, businessId);
    }

    updateQuality(input: UpdateClaQualityInput): Observable<void> {
        this.setStepToReview(ClaStatus.Quality);
        return this.qualityService.updateQualityList(input).pipe(tap((output: UpdateClaQualityOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

     createPlaceholderServiceArea(caseId: string, businessId: number): Observable<number> {
         return this.serviceAreasService.createPlaceholderServiceArea(caseId, businessId);
     }

     updateServiceArea(input: UpdateClaServiceAreasInput): Observable<void> {
         this.setStepToReview(ClaStatus.ServiceArea);
         return this.serviceAreasService.updateServiceAreasList(input).pipe(tap((output: UpdateClaServiceAreasOutput) => {
             this.cla.businesses = output.businesses;
             this.cla.statusId = output.status;
         })).pipe(map(() => null));
     }

    createPlaceholderPersonel(caseId: string, businessId: number): Observable<number> {
        return this.personelService.createPlaceholderPersonel(caseId, businessId);
    }

    updatePersonel(input: UpdateClaPersonelInput): Observable<void> {
        this.setStepToReview(ClaStatus.Personel);
        return this.personelService.updatePersonelList(input).pipe(tap((output: UpdateClaPersonelOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getPersonelFiles(personelId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.Personel;
        input.subItemId = personelId;
        return this.documentService.getPersonelFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadPersonelFiles(personelId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadPersonelFiles(personelId, input);
    }

    createPlaceholderInfrastructure(caseId: string, businessId: number): Observable<number> {
        return this.infrastructureService.createPlaceholderInfrastructure(caseId, businessId);
    }

    updateInfrastructure(input: UpdateClaInfrastructureInput): Observable<void> {
        this.setStepToReview(ClaStatus.Infrastructure);
        return this.infrastructureService.updateInfrastructureList(input).pipe(tap((output: UpdateClaInfrastructureOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getInfrastructureFiles(infrastructureId: number, hasConstructionalMeasuresId: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.Infrastructure;
        input.subItemId = infrastructureId;
        input.hasConstructionalMeasuresId = hasConstructionalMeasuresId;
        return this.documentService.getInfrastructureFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadInfrastructureFiles(infrastructureId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadInfrastructureFiles(infrastructureId, input);
    }

    getMedicalFieldPersons(): Observable<ClaMedicalFieldPersonDto[]> {
        return of(this.cla.medicalFieldPersons);
    }

    createPlaceholderMedicalFieldPerson(caseId: string): Observable<number> {
        return this.medicalFieldPersonService.createPlaceholderMedicalFieldPerson(caseId);
    }

    updateMedicalFieldPersons(input: UpdateClaMedicalFieldPersonsInput): Observable<void> {
        this.setStepToReview(ClaStatus.MedicalFieldPerson);
        return this.medicalFieldPersonService.updateMedicalFieldPersons(input).pipe(tap((output: UpdateClaMedicalFieldPersonsOutput) => {
            this.cla.medicalFieldPersons = output.medicalFieldPersons;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getMedicalFieldPersonFiles(medicalFieldPersonId: number, hasProfessionalLicenceId: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.MedicalFieldPerson;
        input.subItemId = medicalFieldPersonId;
        input.hasProfessionalLicenceId = hasProfessionalLicenceId;
        return this.documentService.getMedicalFieldPersonFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadMedicalFieldPersonFiles(medicalFieldPersonId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadMedicalFieldPersonFiles(medicalFieldPersonId, input);
    }

    getAssistantPhysicians(): Observable<ClaAssistantPhysicianDto[]> {
        return of(this.cla.assistantPhysicians);
    }

    createPlaceholderAssistantPhysician(caseId: string): Observable<number> {
        return this.assistantPhysicianService.createPlaceholderAssistantPhysician(caseId);
    }

    updateAssistantPhysicians(input: UpdateClaAssistantPhysiciansInput): Observable<void> {
        this.setStepToReview(ClaStatus.AssistantPhysicians);
        return this.assistantPhysicianService.updateAssistantPhysicians(input).pipe(tap((output: UpdateClaAssistantPhysiciansOutput) => {
            this.cla.assistantPhysicians = output.assistantPhysicians;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getAssistantPhysicianFiles(assistantPhysicianId: number, hasAssistantsLicenceId: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.AssistantPhysicians;
        input.subItemId = assistantPhysicianId;
        input.hasAssistantsLicenceId = hasAssistantsLicenceId;
        return this.documentService.getAssistantPhysicianFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadAssistantPhysicianFiles(assistantPhysicianId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadAssistantPhysicianFiles(assistantPhysicianId, input);
    }

    createPlaceholderRangeOfProducts(caseId: string, businessId: number): Observable<number> {
        return this.rangeOfProductsService.createPlaceholderRangeOfProducts(caseId, businessId);
    }

    updateRangeOfProducts(input: UpdateSlasRangeOfProductsInput): Observable<void> {
        this.setStepToReview(ClaStatus.RangeOfProducts);
        return this.rangeOfProductsService.updateRangeOfProductsList(input).pipe(tap((output: UpdateSlasRangeOfProductsOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getRangeOfProductsFiles(rangeOfProductsId: number, hasXRayMachineId: string, hasOperatingTheatresId: string, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.RangeOfProducts;
        input.subItemId = rangeOfProductsId;
        input.hasXRayMachineId = hasXRayMachineId;
        input.hasOperatingTheatresId = hasOperatingTheatresId;
        return this.documentService.getRangeOfProductsFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadRangeOfProductsFiles(rangeOfProductsId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadRangeOfProductsFiles(rangeOfProductsId, input);
    }

    createPlaceholderProductPreparation(caseId: string, businessId: number): Observable<number> {
        return this.productPreparationService.createPlaceholderProductPreparation(caseId, businessId);
    }

    updateProductPreparation(input: UpdateSlasProductPreparationInput): Observable<void> {
        this.setStepToReview(ClaStatus.ProductPreparation);
        return this.productPreparationService.updateProductPreparationList(input).pipe(tap((output: UpdateSlasProductPreparationOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderLocalDrugSales(caseId: string, businessId: number): Observable<number> {
        return this.localDrugSalesService.createPlaceholderLocalDrugSales(caseId, businessId);
    }

    updateLocalDrugSales(input: UpdateSlasLocalDrugSalesInput): Observable<void> {
        this.setStepToReview(ClaStatus.LocalDrugSales);
        return this.localDrugSalesService.updateLocalDrugSalesList(input).pipe(tap((output: UpdateSlasLocalDrugSalesOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    createPlaceholderDrugProduction(caseId: string, businessId: number): Observable<number> {
        return this.drugProductionService.createPlaceholderDrugProduction(caseId, businessId);
    }

    updateDrugProduction(input: UpdateSlasDrugProductionInput): Observable<void> {
        this.setStepToReview(ClaStatus.DrugProduction);
        return this.drugProductionService.updateDrugProductionList(input).pipe(tap((output: UpdateSlasDrugProductionOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    getDrugProductionFiles(drugProductionId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.DrugProduction;
        input.subItemId = drugProductionId;
        return this.documentService.getDrugProductionFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadDrugProductionFiles(drugProductionId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadDrugProductionFiles(drugProductionId, input);
    }

    createPlaceholderDrugProductionThirdParty(caseId: string, businessId: number): Observable<number> {
        return this.drugProductionThirdPartyService.createPlaceholderDrugProductionThirdParty(caseId, businessId);
    }

    updateDrugProductionThirdParty(input: UpdateSlasDrugProductionThirdPartyInput): Observable<void> {
        this.setStepToReview(ClaStatus.DrugProductionThirdParty);
        return this.drugProductionThirdPartyService.updateDrugProductionThirdPartyList(input).pipe(tap((output: UpdateSlasDrugProductionThirdPartyOutput) => {
            this.cla.businesses = output.businesses;
            this.cla.statusId = output.status;
        })).pipe(map(() => null));
    }

    completeOnlineDrugSales(): Observable<void> {
        this.setStepToReview(ClaStatus.OnlineDrugSales);
        return this.onlineDrugSalesService.completeOnlineDrugSales(this.caseId).pipe(tap((statusId: ClaStatus) => {
            this.cla.statusId = statusId;
        })).pipe(map(() => null));
    }

    getOnlineDrugSalesFiles(onlineDrugSalesId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetClaSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = ClaStatus.OnlineDrugSales;
        input.subItemId = onlineDrugSalesId;
        return this.documentService.getOnlineDrugSalesFiles(input).pipe(map((output: ClaDocumentsOutput) => output.documents));
    }

    uploadOnlineDrugSalesFiles(onlineDrugSalesId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadOnlineDrugSalesFiles(onlineDrugSalesId, input);
    }

    getInvoiceAddress(): Observable<ClaInvoiceAddressDto> {
        return of(this.cla.invoiceAddress).pipe(delay(1));
    }

    getInvoiceAddressDetail(): Observable<ClaInvoiceAddressDetail> {
        let detail = new ClaInvoiceAddressDetail({
            name: this.cla.sponsorship.name,
            street: this.cla.sponsorship.street,
            streetNr: this.cla.sponsorship.streetNr,
            postCode: this.cla.sponsorship.postCode,
            city: this.cla.sponsorship.city,
            email: this.cla.sponsorship.email,
            countryId: this.cla.sponsorship.countryId,
        });
        return of(detail).pipe(delay(0));
    }

    createOrUpdateClaInvoiceAddress(input: CreateOrUpdateClaInvoiceAddressInput): Observable<void> {
        this.setStepToReview(ClaStatus.InvoiceAddress);
        return this.invoiceAddressService.createOrUpdateClaInvoiceAddress(input).pipe(tap((statusId: ClaStatus) => {
            this.cla.invoiceAddress = input.claInvoiceAddress;
            this.cla.statusId = statusId;
        }))
            .pipe(map((statusId: ClaStatus) => null));
    }

    checkDocuments(): Observable<ClaStatus[]> {
        return this.claService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }


    getAlternativeTitleBySponsorshipType(sponsorshipTypeId: string, defaultTitle: string): string {
        switch (defaultTitle) {
            case 'AssistantPhysician':
                switch (sponsorshipTypeId) {
                    case this.codes.sponsorshipType.outpatientDentalClinic:
                    case this.codes.sponsorshipType.outpatientDentalClinic:
                        return 'AssistantDentist';
                    case this.codes.sponsorshipType.outpatientChiropractorClinic:
                        return 'AssistantChiropractor';
                    default:
                        return defaultTitle;
                }

            case 'MedicalFieldPhysician':
                switch (sponsorshipTypeId) {
                    case this.codes.sponsorshipType.outpatientDentalClinic:
                    case this.codes.sponsorshipType.outpatientDentalClinic:
                        return 'MedicalFieldDentist';
                    case this.codes.sponsorshipType.outpatientChiropractorClinic:
                        return 'MedicalFieldChiropractor';
                    default:
                        return defaultTitle;
                }
            default:
                return defaultTitle;
        }
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case ClaStatus.Sponsorship:
                return 'sponsorship';
            case ClaStatus.SponsorshipDirectors:
                return 'sponsorship-directors';
            case ClaStatus.MedicalFieldDirector:
                return 'medical-field-director';
            case ClaStatus.MedicalFieldDirectorRepresentative:
                return 'medical-field-director-representative';
            case ClaStatus.Business:
               return 'businesses';
            case ClaStatus.BusinessDirectors:
                return 'business-directors';
            case ClaStatus.RangeOfServices:
                return 'range-of-services';
            case ClaStatus.Treatment:
                return 'treatment';
            case ClaStatus.NursingBeds:
                return 'nursing-beds';
            case ClaStatus.Floors:
                return 'floors';
            case ClaStatus.WheelchairAccessibility:
                return 'wheelchair-accessibility';
            case ClaStatus.AidingResources:
                return 'aiding-resources';
            case ClaStatus.DrugChambers:
                return 'drug-chambers';
            case ClaStatus.Security:
                return 'security';
            case ClaStatus.SanitaryFacilities:
                return 'sanitary-facilities';
            case ClaStatus.Infrastructure:
                return 'infrastructure';
            case ClaStatus.ServiceArea:
                return 'service-area';
            case ClaStatus.Personel:
                return 'personel';
            case ClaStatus.Quality:
                return 'quality';
            case ClaStatus.MedicalFieldPerson:
                return 'medical-field-person';
            case ClaStatus.AssistantPhysicians:
                return 'assistant-physician';
            case ClaStatus.RangeOfProducts:
                return 'range-of-products';
            case ClaStatus.ProductPreparation:
                return 'product-preparation';
            case ClaStatus.LocalDrugSales:
                return 'local-drug-sales';
            case ClaStatus.DrugProduction:
                return 'drug-production';
            case ClaStatus.DrugProductionThirdParty:
                return 'drug-production-third-party';
            case ClaStatus.OnlineDrugSales:
                return 'online-drug-sales';
            case ClaStatus.InvoiceAddress:
                return 'invoice-address';
            case ClaStatus.Release:
                return 'release';
            case ClaStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
