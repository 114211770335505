import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HealthRegisterService } from './health-register.service';

@Injectable()
export class HealthRegisterGuard implements CanActivate {

    constructor(
        private healthRegisterService: HealthRegisterService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.healthRegisterService.loadProfessionRegisterAffiliations().pipe(map(() => true));
    }
}
